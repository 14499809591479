import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const getCampaignThunk = async (_, thunkAPI) => {
  
  const { page, search, searchStatus, searchType, sort,limit } =
    thunkAPI.getState().campaignList;

//   let url = `/jobs?status=${searchStatus}&jobType=${searchType}&sort=${sort}&page=${page}`;
//   if (search) {
//     url = url + `&search=${search}`;
//   }
 let pagevalue = page;
  if(pagevalue > 1){
    pagevalue= (pagevalue  - 1)* limit;
  }else{
    pagevalue = 0;
  }
let dataObj = '';
let url = '';
if(search !=""){
 url = '/getObdcampaignlogSearchByMob';
 dataObj = {
    "user_id":thunkAPI.getState().user.user.innerSystemData.userdata[0].id,
    "limit":limit,
    "mobile_no": "+91"+search,
    "offset":pagevalue
  }
}else{
 url = '/getObdcampaignlog';
 dataObj = {
    "user_id":thunkAPI.getState().user.user.innerSystemData.userdata[0].id,
    "limit":limit,
    "offset":pagevalue
  }
}

  try {
    const resp = await customFetch.post(url,dataObj);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const createCampaignThunk = async(url,dataObj,thunkAPI) =>{
    try {
        
    const resp = await customFetch.post(url,dataObj);
    
let obj = resp.data;
    return obj;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
}