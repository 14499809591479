import PageHeader from "../assets/wrappers/HeaderContainer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const HeadContainer = ({ title, buttonObj, searchobj }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onCreate = (e, url, type) => {
    if (type == "navigate") {
      navigate(url);
    }
  };
  return (
    <PageHeader>
      <div className="row">
        <div className="col">
          <h2>{title}</h2> 
        </div>
        <div className="col-md-auto">
          {searchobj != null ? (
            <input
              type="textbox"
              id="txtsearch"
              className="form-control"
              placeholder="Search"
            />
          ) : (
            ""
          )}
        </div>
        {(buttonObj.length > 0) ? 
        <div className="col col-lg-2">
          {buttonObj.map((btn) => {
            return (
              <button
                type="button"
                name={btn.title}
                className="btn btn-primary"
                onClick={(e) => onCreate(e, btn.url, btn.type)}
              >
                {btn.title}
              </button>
            );
          })}
        </div>
        : ""} 
      </div>
    </PageHeader>
  );
};
export default HeadContainer;
