import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAllPhoneBookThunk } from './phoneThunk';

const initialFiltersState = {
  search: '',
  searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
};

const initialState = {
  isLoading: true,
  phonebooklist: [],
  totalphonebook: 0,
  numOfPages: 1,
  page: 1,
  limit:10,
  ...initialFiltersState,
};

export const getAllPhoneBooks = createAsyncThunk('/getPhonebooklogs', getAllPhoneBookThunk);

//export const showStats = createAsyncThunk('allJobs/showStats', showStatsThunk);

const phoneSlice = createSlice({
  name: 'phoneBook',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      
      state.page = 1;
      state[name] = value;
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState };
    },
    changePage: (state, { payload }) => {
      
      state.page = payload;
    },
    clearAllJobsState: (state) => initialState,
  },
  extraReducers: {
    [getAllPhoneBooks.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllPhoneBooks.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.phonebooklist = payload.data;
      
      state.numOfPages = Math.ceil(payload.total_count/state.limit);
      state.totalphonebook = payload.total_count;
    },
    [getAllPhoneBooks.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    // [showStats.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [showStats.fulfilled]: (state, { payload }) => {
    //   state.isLoading = false;
    //   state.stats = payload.defaultStats;
    //   state.monthlyApplications = payload.monthlyApplications;
    // },
    // [showStats.rejected]: (state, { payload }) => {
    //   state.isLoading = false;
    //   toast.error(payload);
    // },
  },
});

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllJobsState,
} = phoneSlice.actions;

export default phoneSlice.reducer;
