import styled from "styled-components";

const Wrapper = styled.aside`
  display: none;
  @media (min-width: 992px) {
    display: block;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1);
    .sidebar-container {
      // background: linear-gradient(180deg, #f3efff, #ffedf1) !important;
      background:#040b1c;
      min-height: 100vh;
      height: 100%;
      width: 250px;
      margin-left: -250px;
    
    }
    .content {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    .show-sidebar {
      margin-left: 0;
    }
    header {
      // height: 4rem;
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
    }
    header img {
      width: 80%;
      margin-top:10px;
    }
    .nav-links {
      padding-top: 0.5rem;
      display: flex;
      flex-direction: column;
      margin-top:15px;
      border-top: 1px solid #505050;
    }
    .nav-link {
      display: flex;
      align-items: center;
      color: var(--navblue);
      padding: 1rem 0;
      padding-left: 0.5rem;
      text-transform: capitalize;
      transition: var(--transition);
      font-size: 14px;
    }
    .nav-link:hover {
      padding-left: 0.9rem;
      color:#fff;
    }
    .nav-link:hover .icon {
      color: var(--white);
    }
    .nav-link.active{
      color:#fff;
    }
    .nav-link.active:hover{
      color:#fff;
    }
    .icon {
      font-size: 1.2rem;
      margin-right: 1rem;
      display: grid;
      place-items: center;
      transition: var(--transition);
    }
  }
`;
export default Wrapper;
