import "../assets/css/pagination.css";

const PaginationComponent = ({ page, limit, total, paginate }) => {
	// page = current page || total = total items || pageSize = limit

	let totalPages = Math.ceil(total / limit);
	let startPage;
	let endPage;

	if (totalPages <= 10) {
		startPage = 1;
		endPage = totalPages;
	} else {
		if (page <= 6) {
			startPage = 1;
			endPage = 10;
		} else if (page + 4 >= totalPages) {
			startPage = totalPages - 9;
			endPage = totalPages;
		} else {
			startPage = page - 5;
			endPage = page + 4;
		}
	}

	let startIndex = (page - 1) * limit;
	let endIndex = Math.min(startIndex + limit - 1, total - 1);

	let pages = [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);

	return (
		<div>
			<nav>
				<ul className="pagination">
					<li className={page === 1 ? "page-item disabled" : "page-item"} key="start_key">
						<a
							onClick={() => {
								paginate(1);
							}}
							className="page-link"
						>
							&laquo;
						</a>
					</li>

					<li className={page === 1 ? "page-item disabled" : "page-item"} key="prev_key">
						<a
							onClick={() => {
								paginate(page - 1);
							}}
							className="page-link"
						>
							&lsaquo;
						</a>
					</li>

					{pages.map((pageno) => (
						<li className={page === pageno ? "page-item active" : "page-item"} key={"page_item_" + pageno}>
							<a
								onClick={() => {
									paginate(pageno);
								}}
								className="page-link"
							>
								{pageno}
							</a>
						</li>
					))}

					<li className={page === totalPages ? "page-item disabled" : "page-item"} key="next_key">
						<a
							onClick={() => {
								paginate(page + 1);
							}}
							className="page-link"
						>
							&rsaquo;
						</a>
					</li>

					<li className={page === totalPages ? "page-item disabled" : "page-item"} key="end_key">
						<a
							onClick={() => {
								paginate(totalPages);
							}}
							className="page-link"
						>
							&raquo;
						</a>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default PaginationComponent;
