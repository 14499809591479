import axios from 'axios';
import useSelector from 'react-redux';
import { getUserFromLocalStorage } from './localStorage';
//import { clearStore } from '../features/user/userSlice'; 
var x_api_key = "fR2HbWKooT6Uf4xo5R7iD2PetkcXvWzT1g1xDV29";
var baseURL = 'http://10.55.3.248:8082/gbmCampaignAPI/';
//https://3d24-35-154-183-225.in.ngrok.io/gbmCampaignAPI/
//var x_api_key = "6m9Ux0on1k1opZ1qyEZMr4cl29UfAPqK2rryZCZR";
// if(window.location.hostname.includes('localhost')){
//   baseURL='http://localhost/role_based_hierarchy/'
// }

const GBMapi = axios.create({
  baseURL: baseURL,
});

GBMapi.interceptors.request.use((config) => {
  
const user = getUserFromLocalStorage();
//   if (user) {
  //config.headers.common['x-api-key'] = 'fR2HbWKooT6Uf4xo5R7iD2PetkcXvWzT1g1xDV29';
  
 
  //if (user) {
    config.headers.common['x-api-key'] = user.token;
  //}
    //config.headers.common['content-type'] = `application/json`;
    //{ 'content-type': 'application/json', 'x-api-key': x_api_key }
  //}
  return config;
});

// export const checkForUnauthorizedResponse = (error, thunkAPI) => {
//   
//   if (error.response.status === 401) {
//     thunkAPI.dispatch(clearStore());
//     return thunkAPI.rejectWithValue('Unauthorized! Logging Out...');
//   }
//   return thunkAPI.rejectWithValue(error.response.data.msg);
// };

export default GBMapi;
