const NoDataFound = () => {
  const noDataFoundWpr = {
    textAlign: "center",
    margin: "100px 0",
  };
  const noDataWpr = {
    width: "100%",
    height: "300px",
  };
  const noDataWprImg = {
    objectFit: "contain",
    objectPosition: "center",
  };
  return (
    <div style={noDataFoundWpr}>
      <div style={noDataWpr}>
        <img
          src="https://dev-unify.gupshup.io/GenericComponent/resources/images/datanotfound.png"
          width="100%"
          height="100%"
          style={noDataWprImg}
        />
      </div>
    </div>
  );
};
export default NoDataFound;
