import axios from 'axios';
//import { clearStore } from '../features/user/userSlice';
//import { getUserFromLocalStorage } from './localStorage';
var x_api_key = "fR2HbWKooT6Uf4xo5R7iD2PetkcXvWzT1g1xDV29";
var baseURL = 'https://eorw049nn7u85pl.m.pipedream.net/';
//var x_api_key = "6m9Ux0on1k1opZ1qyEZMr4cl29UfAPqK2rryZCZR";
// if(window.location.hostname.includes('localhost')){
//   baseURL='http://localhost/role_based_hierarchy/'
// }
const thirdPartyAPI = axios.create({
  baseURL: baseURL,
});

thirdPartyAPI.interceptors.request.use((config) => {
  
//   const user = getUserFromLocalStorage();
//   if (user) {
  config.headers.common['x-api-key'] = 'fR2HbWKooT6Uf4xo5R7iD2PetkcXvWzT1g1xDV29';
    //config.headers.common['x-api-key'] = `${x_api_key}`;
    //config.headers.common['content-type'] = `application/json`;
    //{ 'content-type': 'application/json', 'x-api-key': x_api_key }
  //}
  return config;
});

// export const checkForUnauthorizedResponse = (error, thunkAPI) => {
//   
//   if (error.response.status === 401) {
//     thunkAPI.dispatch(clearStore());
//     return thunkAPI.rejectWithValue('Unauthorized! Logging Out...');
//   }
//   return thunkAPI.rejectWithValue(error.response.data.msg);
// };

export default thirdPartyAPI;
