import { useState, useEffect } from "react";
import { Logo, FormRow } from "../components";
import Wrapper from "../assets/wrappers/RegisterPage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  registerUser,
  knowlarityloginUser,
} from "../features/user/userSlice";
import { useNavigate } from "react-router-dom";

const initialState = {
  username: "",
  password: "",
  agent_first_name: "",
  agent_last_name: "",
  api_key: "",
  user_id: "",
  isMember: true,
};

function Register() {
  const [values, setValues] = useState(initialState);
  const { user, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();

    const { username, password } = values;
    if (!username || !password) {
      toast.error("Please fill out all fields");
      return;
    }
    //if (user.isMember) {
    //dispatch(loginUser({ email: email, password: password }));
    const execute = async () => {
      const knowlarityAuthentication = await dispatch(
        knowlarityloginUser({ username: username, password: password })
      );
    };

    execute();

    return;
    //}
    //dispatch(registerUser({ name, email, password }));
  };

  const toggleMember = () => {
    setValues({ ...values, isMember: !values.isMember });
  };
  useEffect(() => {
    // && typeof(user.innerSystemData) != "undefined"
    if (user) {
      setTimeout(() => {
        debugger
        navigate("/CreateOrder");
      }, 2000);
    }
  }, [user]);
  return (
    <Wrapper className="full-page">
      <div className="align-middle col-md-4">
        <div className="mb25">
          <Logo></Logo>
        </div>
        {/* <img
          className="ui"
          src={process.env.PUBLIC_URL + "/analytics-banner.svg"}
          alt="Gupshup"
          title="Gupshup"
        /> */}
        <div></div>
      </div>
      <div className="align-middle col-md-4">
        <div className="login-block">
          <div className="text-left">
            <h2>Log In</h2>
            <p>
              {/* <span>
                <i className="whatsapp icon"></i>Support Tools{" "}
                <i className="chart bar icon"></i> by Gupshup.
              </span> */}
            </p>
          </div>
          <form onSubmit={onSubmit} className="login-form">
            <FormRow
              type="text"
              name="username"
              value={values.username}
              handleChange={handleChange}
            />
            <FormRow
              type="password"
              name="password"
              value={values.password}
              handleChange={handleChange}
            />
            <button
              type="submit"
              className="btn btn-block gs-button"
              disabled={isLoading}
            >
              {isLoading ? "loading..." : "submit"}
            </button>
          </form>
        </div>
      </div>
      {/* <div className='row'>
        <div className='col-md-8'>
          <section className=''>
             <h2><Logo/></h2>
          </section>
         
                  </div>
        <div className='col-md-4'>
          <form className='form' onSubmit={onSubmit}>
 
        <h3>{values.isMember ? 'Login' : 'Register'}</h3>
 
         
       
        <FormRow
          type='text'
          name='username'
          value={values.username}
          handleChange={handleChange}
        />
   
        <FormRow
          type='password'
          name='password'
          value={values.password}
          handleChange={handleChange}
        />
        <button type='submit' className='btn btn-block' disabled={isLoading}>
          {isLoading ? 'loading...' : 'submit'}
        </button>
         
      </form>
        </div>
      </div> */}
    </Wrapper>
  );
}
export default Register;
