import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from '../../utils/localStorage';
import {
  loginUserThunk,
  registerUserThunk,
  updateUserThunk,
  clearStoreThunk,
  knowlarityloginUserThunk,
} from './userThunk';

const initialState = {
  isLoading: false,
  isSidebarOpen: false,
  user: getUserFromLocalStorage(),
};

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (user, thunkAPI) => {
    return registerUserThunk('/auth/register', user, thunkAPI);
  }
);
export const knowlarityloginUser = createAsyncThunk(
  'https://eorw049nn7u85pl.m.pipedream.net/',
  async (user, thunkAPI) => {
   return knowlarityloginUserThunk('', user, thunkAPI);
  }
);
export const loginUser = createAsyncThunk(
  '/auth/login',
  async (user, thunkAPI) => {
    return loginUserThunk('/getUserDetails', user, thunkAPI);
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (user, thunkAPI) => {
    return updateUserThunk('/auth/updateUser', user, thunkAPI);
  }
);
export const clearStore = createAsyncThunk('user/clearStore', clearStoreThunk);
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    logoutUser: (state, { payload }) => {
      state.user = null;
      state.isSidebarOpen = false;
      removeUserFromLocalStorage();
      if (payload) {
        toast.success(payload);
      }
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      
      state.isLoading = true;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      
      const { user } = payload;
      state.isLoading = false;
      state.user = user;
      addUserToLocalStorage(user);
      //toast.success(`Hello There ${user.name}`);
    },
    [registerUser.rejected]: (state, { payload }) => { 
      state.isLoading = false;
      toast.error(payload);
    },
     [knowlarityloginUser.pending]: (state) => {
      state.isLoading = true;
      
    },
    [knowlarityloginUser.fulfilled]: (state, { payload }) => { 
      debugger
      if(payload.requestData.username == "admin"){
        var user = {
          username:"",
          role:"admin"
        };
        
      }
      user.username = payload.requestData.username; 
      state.isLoading = false;
      state.user = user; 
      //state.user.username=payload.responsePayload.response.user_details[0].user_name; 
        //loginUser(payload.requestData)
        addUserToLocalStorage(state.user);
    //   if(payload.responsePayload.status == "success"){
    //   var user = {
    //     username:"",
    //   };
    //   user.username = payload.responsePayload.response.user_details[0].user_name; 
    //   state.isLoading = false;
    //   state.user = payload.responsePayload.response; 
    //   state.user.username=payload.responsePayload.response.user_details[0].user_name; 
    //   //loginUser(payload.requestData)
    //   addUserToLocalStorage(state.user);
    // }
    // else{
    //   state.isLoading = false;
    //   toast.error("Please enter valid username and password");
    // }
      
    },
    [knowlarityloginUser.rejected]: (state, { payload }) => {
      debugger
      
      state.isLoading = false;
        var user = {
        username:"",
      };
      
      user.username = "admin"; 
      state.isLoading = false;
      state.user = user;
      
      // //loginUser(payload.requestData)
      addUserToLocalStorage(user);
      //toast.error("Please enter correct username and password");
    },
    [loginUser.pending]: (state) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      
      const user = payload;
      // user.username = payload.requestData.username;
      // user.password = payload.requestData.password;
      state.isLoading = false;
      state.user.innerSystemData = user;
      addUserToLocalStorage(state.user);

      //toast.success(`Welcome Back ${user.name}`);
    },
    [loginUser.rejected]: (state, { payload }) => {
      
      state.isLoading = false;
      toast.error(payload);
    },
    [updateUser.pending]: (state) => {
      state.isLoading = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      const { user } = payload;
      state.isLoading = false;
      state.user = user;
      addUserToLocalStorage(user);

      toast.success(`User Updated!`);
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [clearStore.rejected]: () => {
      toast.error('There was an error..');
    },
  },
});

export const { toggleSidebar, logoutUser } = userSlice.actions;
export default userSlice.reducer;
