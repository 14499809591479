import styled from "styled-components";

const ChatBotPreview = styled.section`
  .msgtempwpr {
    margin-top: 15px;
    border: 1px solid #d5d5d5;
    padding: 10px;
  }
  .color414 {
    color: #414e5a;
  }
  .color777 {
    color: #777;
  }
  .mb5 {
    margin-bottom: 5px;
  }
  .msgreplybtn li a span.font11 {
    font-size: 11px;
  }
  .msgimgwpr {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: #416bc1;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    flex-shrink: 0;
  }
  .msgtemptext .msgtemprgph {
    margin: 0;
    background: #f2f2f3;
    padding: 15px;
    border-radius: 15px;
    border-top-left-radius: 0;
    font-size: 12px;
    word-break: break-all;
  }
  .msgmints {
    color: #414e5a;
    font-size: 11px;
    display: block;
    margin-top: 5px;
    text-indent: 45px;
  }
  .msgimgtxtwpr {
    display: flex;
    align-items: normal;
    gap: 10px;
  }
  .msgreplybtn {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }
  .msgreplybtn {
    margin: 5px 0 0 0;
    flex-wrap: wrap;
  }
  .msgreplybtn,
  .msgreplybtn li {
    list-style-type: none;
  }
  /* .msgreplybtn li {
        flex-grow: 1;
      } */
  .msgreplybtn li a {
    border: 1px solid #d5d5d5;
    color: #414e5a;
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 12px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .msgreplybtn li a span {
    display: block;
    font-size: 8px;
  }
  .msgtemptext img {
    background: rebeccapurple;
  }
  .calIcon,
  .gotowebsite,
  .signupIcon,
  .whitewebsiteIcon {
    background-image: url(callIcons.png);
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    background-size: 100%;
    background-position: center;
    flex-shrink: 0;
  }
  .gotowebsite {
    background-image: url(gotowebsite.png);
  }
  .signupIcon {
    background-image: url(signuplock.png);
  }
  .whitewebsiteIcon {
    background-image: url(whitewebsite.png);
    display: block;
  }
  /*Image Card Css*/
  .msgimgcard .msgtemptext {
    border-radius: 12px;
    border: 1px solid #d5d5d5;
    overflow: hidden;
    width: 100%;
  }
  .msgimgcard .msgtemptext .msgimgbanner {
    height: 200px;
    width: 100%;
  }
  .msgimgcard .imgbtmcard {
    padding: 10px;
  }
  .msgimgcard ul li {
    display: block;
  }
  .msgimgcard .chkbtnwpr {
    margin-top: 15px;
  }
  .msgimgcard .chkbtnwpr button {
    background: #3672e1;
    color: #fff;
    border-radius: 6px;
    border: 0;
    outline: 0;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    gap: 3px;
  }
  /*Image Card Css End*/

  /*Image Carausel Css*/
  .msgimgcard.carausel .msgtemptext {
    width: 212px;
    flex-shrink: 0;
  }
  .msgimgcard.carausel .msgimgtxtwpr {
    overflow-x: auto;
  }
  .msgimgcard.carausel .msgimgtxtwpr::-webkit-scrollbar {
    display: none;
  }
  .msgimgcard.carausel .msgmints {
    text-indent: 0;
  }
`;

export default ChatBotPreview;
