import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAllCallLogsThunk } from './callLogsThunk';
import { useSelector, useDispatch } from 'react-redux';
const initialFiltersState = {
  search: '',
  searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
};
 

const initialState = {
  isLoading: true,
  callLogList: [],
  totalCallLogCount: 0,
  numOfPages: 1,
  page: 1,
  limit:50,
  orderid:0,
  ...initialFiltersState,
};

export const getAllCallLogs = createAsyncThunk('/getCallCampaignLog', getAllCallLogsThunk);

//export const showStats = createAsyncThunk('allJobs/showStats', showStatsThunk);

const callLogSlice = createSlice({
  name: 'callLogs',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
      //state.orderid=a;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      
      state.page = 1;
      state[name] = value;
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState };
    },
    changePage: (state, { payload }) => {
      
      state.page = payload;
    },
    clearAllJobsState: (state) => initialState,
  },
  extraReducers: {
    [getAllCallLogs.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllCallLogs.fulfilled]: (state, { payload }) => {
        
      state.isLoading = false;
      state.callLogList = payload.data;
      
      state.numOfPages = Math.ceil(payload.total_count/state.limit);
      state.totalCallLogCount = payload.total_count;
    },
    [getAllCallLogs.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    // [showStats.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [showStats.fulfilled]: (state, { payload }) => {
    //   state.isLoading = false;
    //   state.stats = payload.defaultStats;
    //   state.monthlyApplications = payload.monthlyApplications;
    // },
    // [showStats.rejected]: (state, { payload }) => {
    //   state.isLoading = false;
    //   toast.error(payload);
    // },
  },
});

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllJobsState,
} = callLogSlice.actions;

export default callLogSlice.reducer;
