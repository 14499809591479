import styled from 'styled-components'

const Wrapper = styled.section`
  margin-top: 2rem;
  padding:20px;
  background:#fff;
  h2 {
    text-transform: none;
  }
  & > h5 {
    font-weight: 700;
  }
`
export default Wrapper
