import links from "../utils/links";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../components/Accordian";
import { NavLink } from "react-router-dom";
const NavLinks = ({ toggleSidebar }) => {
  const user = useSelector((store) => store.user);
  const [data, setData] = useState([]);
  const [searchData, setSearch] = useState([]);
  const [category, setCategory] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const getData = () => {
    fetch("links.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        setData(myJson);
        setSearch(myJson);
        var categorylist = [];
        for (let index = 0; index < myJson.length; index++) {
          let a = myJson[index].category_name;
          categorylist.push(a);
        }
        let unique = [...new Set(categorylist)];
        console.log(unique);
        setCategory(unique);
      });
  };
  const generateUserBasedLinks = () => {
    var myJson = {
      access_details: [
        {
          id: 1,
          module_url: "createOrder",
          description: "Create Order for Glasso",
          module_name: "Create Order",
          category_name: "",
          accordian: false,
          has_access: 1,
        },
        {
          id: 2,
          module_url: "manageOrder",
          description: "Manage order for Glasso",
          module_name: "Manage Order",
          category_name: "",
          accordian: false,
          has_access: 1,
        }, 
      ],
    };
    setData(myJson.access_details);
    setSearch(myJson.access_details);
    var categorylist = [];
    for (let index = 0; index < myJson.access_details.length; index++) {
      if (myJson.access_details[index].accordian) {
        //         let tempObjCount =  myJson.access_details.filter(function (item) {
        //             return item.category_name == myJson.access_details[index].category_name;
        //         });
        // let tempCatrgory = {category_name: myJson.access_details[index].category_name,count:tempObjCount.length}
        categorylist.push(myJson.access_details[index]);
      }
    }
    const uniqueTags = [];
    categorylist.map(function (a, y) {
      if (uniqueTags.length > 0) {
        for (let j = 0; j < uniqueTags.length; j++) {
          if (uniqueTags[j].category_name.indexOf(a.category_name) === -1) {
            uniqueTags.push(a);
          }
        }
      } else {
        uniqueTags.push(a);
      }
    });
    let unique = [...new Set(uniqueTags)];
    //console.log(unique);
    setCategory(unique);
  };
  useEffect(() => {
    //getData()
    generateUserBasedLinks();
  }, []);
  const dispatch = useDispatch();
  const [seachquery, setsearchquery] = useState("");
  const getfilteredlinks = (seachquery, links) => {
    if (!seachquery) {
      return links;
    }
    return links.filter((x) => x.searchtext.includes(seachquery.toLowerCase()));
  };
  const filteritems = getfilteredlinks(seachquery, links);
  const handleChange = (e) => {
    var a = JSON.parse(JSON.stringify(data));
    a = a.filter((x) => x.searchtext.includes(e.target.value.toLowerCase()));
    setSearch(a);
    setIsActive(true);
  };
  return (
    <div>
      {/* <div className="form-control">
        <input
          type="text"
          className="form-control"
          placeholder="Search any application"
          onChange={handleChange}
        ></input>
      
      </div> */}
      <div className="nav-links">
        <div className="accordion">
          {searchData.map(function (x, y) {
            if (x.accordian == false) {
              var temp = x;
              //delete searchData[y];
              //return(<span>asdas</span>)
              return (
                <div>
                  <NavLink
                    to={temp.module_url}
                    state={{
                      weburl: x.urls,
                      title: temp.module_name,
                      description: temp.mod,
                    }}
                    className={({ isActive }) => {
                      return isActive ? "nav-link active" : "nav-link";
                    }}
                    key={temp.id}
                    onClick={toggleSidebar}
                    id={temp.id}
                  >
                    {temp.module_name}
                  </NavLink>
                </div>
              );
            } else {
            }
          })}
          {category.map(function (x) {
            return (
              <Accordion
                title={x.category_name}
                content={searchData}
                click={toggleSidebar}
                parentIsActive={isActive}
                childlength={x.count}
              />
            );
          })}
        </div>

        {/* <div className="accordion">
{category && category.length>0 && category.map((x) => {
<div>{x}</div>
          {data.map(function (item) {
             if(item.category == x){
             
             }
          })}
        })}
  </div> */}

        {/* {data && data.length>0 && data.map((link) => {
        const { text, path, id, icon,urls,title,description,category} = link;
        return (
          <div className="accordion">
        <div className="accordion-item">
          <div className="accordion-title">
            <div>{category}</div>
            <div>+</div>
          </div>
          <div className="accordion-content"><NavLink
            to={path}
             
             state={{ weburl: urls, title:text,description:description}}
            className={({ isActive }) => {
              return isActive ? 'nav-link active' : 'nav-link';
            }}
            key={id}
            onClick={toggleSidebar}
          >
            <span className='icon'>{icon}</span>
            {text}
          </NavLink></div>
        </div>
      </div>
          
        );
      })} */}
        {/* {links.map((link) => {
        const { text, path, id, icon,urls } = link;
        return (
          <NavLink
            to={path}
             state={{ from: urls }}
            className={({ isActive }) => {
              return isActive ? 'nav-link active' : 'nav-link';
            }}
            key={id}
            onClick={toggleSidebar}
          >
            <span className='icon'>{icon}</span>
            {text}
          </NavLink>
        );
      })} */}
      </div>
    </div>
  );
};
export default NavLinks;
//  {category && category.length>0 && category.map((x)=>
//   <div className="accordion-item">
//             <div
//        className="accordion-title"
//        onClick={() => setIsActive(!isActive)}
//      >
//        <div>{x} {isActive ? '-' : '+'}</div>
//      </div>
//            {isActive && <div className="accordion-content">

//                {data.map(function (item) {
//
//                  if(item.category == x){
//                    return (
//                      <NavLink
//              to={item.path}

//               state={{ weburl: item.urls, title:item.text,description:item.description}}
//              className={({ isActive }) => {
//                return isActive ? 'nav-link active' : 'nav-link';
//              }}
//              key={item.id}
//              onClick={toggleSidebar}
//            >
//              {item.text}
//            </NavLink>
//                    );
//                    }
//                  })}
//                  </div>}
//            </div>)}
