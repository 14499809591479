import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getCampaignThunk } from './campaignThunk';
 
const initialFiltersState = {
  search: '',
  searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
};

const initialState = {
  isLoading: true,
  campaignList: [],
  totalCampaign: 0,
  numOfPages: 1,
  page: 1,
   limit:10,
  ...initialFiltersState,
};

export const getAllCampaign = createAsyncThunk('/getAllCampaign', getCampaignThunk);
 
//export const showStats = createAsyncThunk('allJobs/showStats', showStatsThunk);

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      
      state[name] = value;
        
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState };
    },
    changePage: (state, { payload }) => {
      
      state.page = payload;
    },
    clearAllJobsState: (state) => initialState,
  },
  extraReducers: {
    [getAllCampaign.pending]: (state) => {
      state.isLoading = true;
      //state.campaignList = [{id:1,name:"jimeet Jain",price:"Rs 100"},{id:2,name:"jimeet Jain",price:"Rs 100"}]
    },
    [getAllCampaign.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.campaignList = payload.data;
      
      //state.numOfPages = payload.numOfPages;
      state.totalCampaign = payload.total_count;
      state.numOfPages = Math.ceil(payload.total_count/state.limit);
    },
    [getAllCampaign.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    // [showStats.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [showStats.fulfilled]: (state, { payload }) => {
    //   state.isLoading = false;
    //   state.stats = payload.defaultStats;
    //   state.monthlyApplications = payload.monthlyApplications;
    // },
    // [showStats.rejected]: (state, { payload }) => {
    //   state.isLoading = false;
    //   toast.error(payload);
    // },
  },
});

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllJobsState,
} = campaignSlice.actions;

export default campaignSlice.reducer;
