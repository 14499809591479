import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const getAllCallLogsThunk = async ({order_id,_}, thunkAPI) => {
    
  const { page, limit,orderid} =
    thunkAPI.getState().callLogList;

  // let url = `/jobs?status=${searchStatus}&jobType=${searchType}&sort=${sort}&page=${page}`;
  // if (search) {
  //   url = url + `&search=${search}`;
  // }
  let pagevalue = page;
  if(pagevalue > 1){
    pagevalue= (pagevalue  - 1)* limit;
  }else{
    pagevalue = 0;
  }
  let url = '/getCallCampaignLog'
  let dataObj = {
   // "user_id":thunkAPI.getState().user.user.innerSystemData.userdata[0].id,
    "limit":limit,
    "offset":pagevalue,
    "order_id" : order_id
  }
  
  try {
    const resp = await customFetch.post(url,dataObj);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

