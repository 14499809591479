import { IoBarChartSharp } from 'react-icons/io5';
import { MdQueryStats } from 'react-icons/md';
import { FaWpforms } from 'react-icons/fa';
import { ImProfile } from 'react-icons/im';

const links = [
{ id:1,searchtext:'vmn dashboard - sms',text: 'VMN Dashboard',title: 'VMN Dashboard', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me:8082/rishi/dashboard'}
,{ id:2,searchtext:'usagestats ',text: 'UsageStats ', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me:8083/Rishi'}
,{ id:3,searchtext:'global api ',text: 'Global API ', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me:8999/GlobalAPI'}
,{ id:4,searchtext:'whatsapp utilities (check whatsapp template , update certs etc..)',text: 'Whatsapp Utilities (Check Whatsapp Template , Update Certs etc..)', path: 'web-loaders',urls : 'http://techsupport.awsfb.gupshup.me:8001/rishi/dashboard'}
,{ id:5,searchtext:'analytics admin panel',text: 'Analytics Admin Panel', path: 'web-loaders',urls : 'http://techsupport.awsfb.gupshup.me:8002/rishi/dashboard'}
,{ id:6,searchtext:'whatsapp template enable & disable',text: 'Whatsapp Template Enable & Disable', path: 'web-loaders',urls : 'http://ent-apps-01.awsfb.gupshup.me:8180/'}
,{ id:7,searchtext:'bot reports',text: 'Bot Reports', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Bhavarth/Bot/login.php?'}
,{ id:8,searchtext:'docker setup',text: 'Docker Setup', path: 'web-loaders',urls : 'https://unify.smsgupshup.com/WhatsApp/DockerSetup/'}
,{ id:9,searchtext:'account migration',text: 'Account Migration', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Bhavarth/AccMigration/login.php?'}
,{ id:10,searchtext:'update business profile',text: 'Update Business Profile', path: 'web-loaders',urls : 'http://techsupport.awsfb.gupshup.me/Bhavarth/UpdateProfile/'}
,{ id:11,searchtext:'download reports (whatsapp)',text: 'Download Reports (WhatsApp)', path: 'web-loaders',urls : 'http://techsupport.awsfb.gupshup.me/Bhavarth/DownloadReports/pages/index.php'}
,{ id:12,searchtext:'voice pulse data',text: 'Voice Pulse Data', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Bhavarth/Voice/'}
,{ id:13,searchtext:'gbm manager',text: 'GBM Manager', path: 'web-loaders',urls : 'https://unify.smsgupshup.com/WhatsApp/GBM_manager/data/home.php '}
,{ id:14,searchtext:'kotak summary portal',text: 'Kotak Summary Portal', path: 'web-loaders',urls : 'http://products.smsgupshup.com/Kotak_Summary/Web/'}
,{ id:15,searchtext:'console setup apis',text: 'Console Setup APIs', path: 'web-loaders',urls : 'http://10.55.3.248/Bhavarth/swiggy_proj/createmember.php http://10.55.3.248/Bhavarth/swiggy_proj/createservice.php http://10.55.3.248/Bhavarth/swiggy_proj/createservice.php'}
,{ id:16,searchtext:'',text: '', path: 'web-loaders',urls : 'http://10.30.70.84/Tejas/userIdAccountName.php?userId=1214211879'}
,{ id:17,searchtext:'',text: '', path: 'web-loaders',urls : 'http://10.30.70.84/Tejas/userIdAccountId.php?userId=1214211879'}
,{ id:18,searchtext:'',text: '', path: 'web-loaders',urls : 'http://10.30.70.84/Tejas/entityIdAccountId.php?entityId=1014879514'}
,{ id:19,searchtext:'',text: '', path: 'web-loaders',urls : 'http://10.30.70.84/Tejas/AccountIdUserId.php?userId=2000120475'}
,{ id:20,searchtext:'whatsapp billing summary',text: 'WhatsApp Billing Summary', path: 'web-loaders',urls : 'http://techsupport.awsfb.gupshup.me/Bhavarth/crons/billable_summary/Generic_Summary.sh'}
,{ id:21,searchtext:'whatsapp template summary',text: 'WhatsApp Template Summary', path: 'web-loaders',urls : 'http://techsupport.awsfb.gupshup.me/Bhavarth/crons/template_wise/Generic_templatewise.sh'}
,{ id:22,searchtext:'whatsapp template wise billing summary',text: 'WhatsApp Template wise Billing Summary', path: 'web-loaders',urls : 'http://techsupport.awsfb.gupshup.me/Bhavarth/crons/template_wise_billable/Generic_templatewise.sh'}
,{ id:23,searchtext:'sender id-wise counts sh3',text: 'Sender Id-Wise Counts sh3', path: 'web-loaders',urls : 'http://10.30.70.84/Pradnya/SenderIdDLR/newSenderIDNFS.php'}
,{ id:24,searchtext:'sender id-wise counts key',text: 'Sender Id-Wise Counts Key', path: 'web-loaders',urls : 'http://10.30.30.228/Pradnya/SenderIdDLR/newSenderIDNFS.php'}
,{ id:25,searchtext:'account id from cause id',text: 'Account Id from Cause Id', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Pradnya/CauseId.php'}
,{ id:26,searchtext:'multiple accounts summary (date range)',text: 'Multiple Accounts Summary (Date Range)', path: 'web-loaders',urls : 'http://10.30.70.84/Pradnya/BillingStats/RangeStats.php'}
,{ id:27,searchtext:'account summary for date range',text: 'Account Summary for Date Range', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Pradnya/BillingStats/accountId.php'}
,{ id:28,searchtext:'find accounts on any path',text: 'Find Accounts on any Path', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Pradnya/AccountsOverPath/index.php'}
,{ id:29,searchtext:'accounts summary for kam',text: 'Accounts Summary for KAM', path: 'web-loaders',urls : 'http://10.30.70.100/Pradnya/data/loginIndex.php?'}
,{ id:30,searchtext:'account summary for finance team',text: 'Account Summary for Finance team', path: 'web-loaders',urls : 'http://10.30.70.100/Pradnya/data/index.php'}
,{ id:31,searchtext:'sms spam',text: 'SMS Spam', path: 'web-loaders',urls : 'http://10.30.70.84/Pradnya/Spam/SMSSpam.php'}
,{ id:32,searchtext:'priority table (sms)',text: 'Priority Table (SMS)', path: 'web-loaders',urls : 'http://10.30.70.100/Pradnya/PriorityTable.html'}
,{ id:33,searchtext:'priority string (sms)',text: 'Priority String (SMS)', path: 'web-loaders',urls : 'http://10.30.70.100/Pradnya/priorityString.php'}
,{ id:34,searchtext:'media url servlet',text: 'Media URL Servlet', path: 'web-loaders',urls : 'http://10.30.70.84:8889/Sugam/WhatsAppMediaURL'}
,{ id:35,searchtext:'hex to utf8 conversion',text: 'Hex to UTF8 Conversion', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Pradnya/hex2utf8/hex2utf8.php'}
,{ id:36,searchtext:'waba docker details',text: 'WABA Docker Details', path: 'web-loaders',urls : 'http://techsupport.awsfb.gupshup.me/Pradnya/WADocker/index.php'}
,{ id:37,searchtext:'account id from cause id awsfb',text: 'Account Id from Cause Id awsfb', path: 'web-loaders',urls : 'http://techsupport.awsfb.gupshup.me/Pradnya/CauseId.php'}
,{ id:38,searchtext:'get timestamp from cause id',text: 'Get Timestamp from Cause Id', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me:8040/reportingNew/GetCauseIdTime.jsp'}
,{ id:39,searchtext:'get timestamp from cause id awsfb',text: 'Get Timestamp from Cause Id awsfb', path: 'web-loaders',urls : 'http://apps-monitoring-01.awsfb.gupshup.me:8080/reportingNew/GetCauseIdTime.jsp'}
,{ id:47,searchtext:'user analysis',text: 'User Analysis', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Ravi/UserAnalysis/UserAnalysis.php'}
,{ id:48,searchtext:'cause id wise report for both sh3 and key account',text: 'Cause ID wise report for both SH3 and KEY account', path: 'web-loaders',urls : 'SH3 = http://techsupport.sh3.gupshup.me/Ravi/UserAnalysis/CauseIdMobile.php  KEY = http://10.30.30.114/Ravi/AccountAnalysiskey/CauseIdMobilekey.php'}
,{ id:49,searchtext:'entity id and gateway id wise failure report and more details on perticular global error code',text: 'Entity ID and Gateway ID wise Failure Report and more details on perticular global error code', path: 'web-loaders',urls : 'SH3 = http://techsupport.sh3.gupshup.me/Ravi/UserAnalysis/failurereport/failureReport.php  KEY = http://10.30.30.114/Ravi/failurereportKey/failureReport.php'}
,{ id:50,searchtext:'facebook certificate download ',text: 'Facebook Certificate Download ', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Ravi/UserAnalysis/cerificateDownload.php'}
,{ id:51,searchtext:'account analysis report date wise',text: 'Account Analysis Report date wise', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Ravi/UserAnalysis/UserAccountanalysis.php'}
,{ id:52,searchtext:'multiple dlt template status check(enabled or disabled) - sms',text: 'Multiple DLT template status check(Enabled or disabled)', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Ravi/UserAnalysis/DlttemplateID.php'}
,{ id:53,searchtext:'template count report for sh3 and key both (by default current date selected and time from 00:00 to current when user search for result)',text: 'Template count report for SH3 and KEY both (by default current date selected and time from 00:00 to current when user Search for Result)', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Ravi/UserAnalysis/count.php'}
,{ id:54,searchtext:'ui for usefull links ',text: 'UI for usefull links ', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Ravi/UserAnalysis/links.php'}
,{ id:55,searchtext:'latancy report ',text: 'Latancy Report ', path: 'web-loaders',urls : 'http://10.30.30.114/Ravi/HDFC/LatancyReport.php'}
,{ id:56,searchtext:'docker setup for internal team',text: 'Docker setup for internal team', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/Ravi/DockerSetup/MigrationLogin.php'}
,{ id:64,searchtext:'voice campaign details',text: 'Voice Campaign Details', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/aquib/voice.php'}
,{ id:65,searchtext:'no ack numbers',text: 'No ACK numbers', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/aquib/noack.phphttp://10.30.30.114/aquib/noack.php'}
,{ id:66,searchtext:'cause id details',text: 'Cause Id Details', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/aquib/causeid.phphttp://techsupport.sh3.gupshup.me/aquib/submit_details.php?causeId=4810791496508809277&err=000'}
,{ id:67,searchtext:'error codes of account',text: 'Error Codes of Account', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/aquib/account_stats.phphttp://techsupport.sh3.gupshup.me/aquib/acc_submit_details.php?entityId=1015100407&err=0&timestamp=1672943400000'}
,{ id:68,searchtext:'find account id via entityid',text: 'Find account Id via EntityId', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/aquib/find.php'}
,{ id:69,searchtext:'account usage',text: 'Account Usage', path: 'web-loaders',urls : 'http://techsupport.sh3.gupshup.me/aquib/account_usage.php'}

  
];

export default links;
