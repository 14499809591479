import { FormRow, FormRowSelect, HeadContainer } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import GBMCampaignWrapper from "../../../assets/wrappers/GBMCampaign";
import ChatBotPreview from "../../../assets/wrappers/ChatBotPreview";
import DatePicker from "react-datepicker";
import { DateRangePicker, createStaticRanges, defaultStaticRanges } from "react-date-range";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { format } from "date-fns";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { event, set } from "react-ga";
import { omit } from "lodash";
import { addDays, subDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../../../assets/css/campaign.css";
import GBMapi from "../../../utils/GBMapi";
import moment from "moment";

import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import { Form } from "react-bootstrap";

const lsOrderLst = JSON.parse(localStorage.getItem('orderDetails'));
function validateURL(text) {
	const urlRegex = /^(https?|ftp):\/\/([^\s\/]+)(\/[^\s]*)?/g;
	return urlRegex.test(text);
}
function validatePhone(text) {
	const urlRegex = /^[0-9+\*#-]+$/g;
	return urlRegex.test(text);
}
function validateisNumeric(text) {
	const regex = /^[0-9]*$/; // pattern that matches only numbers
	return regex.test(text);
} 
const MaterialDropdown = ({ selectedMaterial, onMaterialChange }) => {
    // const [selectedMaterial, setSelectedMaterial] = useState("");
  
    
    // const handleMaterialChange = (event) => {
    //   setSelectedMaterial(event.target.value);
    //   //setFormData({ ...formData, selectedMaterial: e.target.value }); 
    // };
  
    return (
      <div>
        <label htmlFor="material-dropdown">Material:</label>
        
      </div>
    );
  };
  const MaterialTypeDropdown = ({selectedType,onMaterialTypeChange}) => {
    // const [selectedType, setSelectedType] = useState(""); 
    // const handleTypeChange = (event) => {
    //  debugger
    //   //setFormData({...formData, selectedType: e.target.value }); 
    //   setSelectedType(event.target.value);
    // };
  
    return (
      <div>
       
      </div>
    );
  };
  
const CreateOrder = () => {
	const navigate = useNavigate();      
  const { state } = useLocation();
  const [tempSubmitData,settempSubmitData] = useState([]);
  const [campaignToEditCalled, setCampaignToEditCalled] = useState(false);
  const [jobIndex, setJobIndex] = useState();
    const [sheetData, setSheetData] = useState({
        TypeOfCut:"",
        Treatment:"",
        OD:false, 
    });
    const [selectedLaminationMaterial, setselectedLaminationMaterial] = useState([]);
    const [jobData, setJobData] = useState([{
      jobID:1,
      id:"",
      width: "",
      length: "",
      gauge: "",
      micron: "",
      qtyType: "Kgs",
      priority: "",
      qtyValue: "",
      qtyValuepc:"",
      totalkgrequired:"",
      flapType: "OFT",
      flapSize: "",
      gusset: "Side",
      gussetSize: "",
      sealing: "Bottom",
      holePunchSize: "",
      holePunchNumber: "",
      cutType: "D-cut",
      weightPerBag: "",
      bagType: "plain",
      printedType: "",
      jobname: "",
      handleType: "",
     
        TypeOfCut: "",
        Treatment: "",
        OD: false,
        jobremark:"",
      jobStatus: "",
      productType: "",
      selectedMaterial:"LDNatural",
      selectedMaterialLamination:"Select Material Type",
      selectedType:"",
      jobCreatedDTS: new Date(),
      jobDeliverDTS: new Date(), 
      //extrussion_planning_datapoints
      extrusion_machine:0,
      tube:0,//formula
      tubeGauge:0,
      extrusionKG:0,
      extrusionRemark:"",
      materialtypeother:"",
      //extrusion_process_datapoints
      extrusionGrossWeight:0,
      extrusionCalculatedWeight:0,
      extrusionNetWeight:0,
      extrusionInMeter:0,
      extrusionGauge:0,
      extrusionQARemark:"",
      subbagType:"others",
      wastepercentage:0,
      extrusionmeterroll:0,
      extrusionpackagingWeight:0,
      dcuttlessweight:0,
      printing_process: false,
      lamination_process: false,
      slitting_process: false,
      gudgeting_process: false,
      cutting_process: false,
      sealing_process: false,
      dispatch_process: false,
      exordertube:0,
      density:0.925,
      printingcolors:[
        {
          selectedColor: "",
          colorhex:"",
        }
      ],
      extroll:[
        {selectedShift :"",
      OperatorName:"",
      extrusionGrossWeight:0,
      extrusionCalculatedWeight:0,
      extrusionpackagingWeight:0,
      exrpm:"",
      extrusionNetWeight:0,
      extrusionGauge:0,
      extrusionInMeter:0,
      extrusionProductionInMeter:0,
      exwastage:0,
      extrusionQARemark:""}],
      flapSizeYN:"No",
      gudetSiseYN:"No",
      holepunchSizeYN:"No",
      qtySheetValueKG:0,
      qtySheetValueMeter:0,
      laminationDetails:[
        {qtylaminatekgs:0,qtylaminatemeter:0}
      ]
    }]);
    const [flapSizeYN,setFlapSizeYN]= useState("");
    const handleflapSizeYNChange = (index,e) =>{
      setFlapSizeYN(e.target.value)
      const { name, value } = e.target;
      var tempName = "";
      setJobIndex(index);
      let event = null;
      let  extraweight = 0;
      let lessweight =0; 
      const updatedList = {
        ...orderDetails,jobindex:index,
        jobList: orderDetails.jobList.map((job, i) => {  
          if (i === index) { 
            
            return {
              ...job,
              [name]: value, 
              event:e, 
            };
          }
          return job;
        })
      };
      setOrderDetails(updatedList); 
      
    };
    const [gudetSiseYN,setgudetSiseYN]= useState("");
    const handelegudetSiseYN = (event) =>{
      setgudetSiseYN(event.target.value)
    };
    const [holepunchSizeYN,setholepunchSizeYN]= useState("");
    const handleholepunchSizeYN = (event) =>{
      setholepunchSizeYN(event.target.value)
    };
    const handlerolechange = (event, index, parentIndex) => {
      
      const { name, value } = event.target;
      setJobIndex(parentIndex); 
      const updatedList = {
        ...orderDetails,jobindex:parentIndex,
        jobList: orderDetails.jobList.map((job, i) => { 
          if (i === parentIndex) { 
            job.extroll[index][name] = value;
            job.event=event;
            job.extrollindex=index;
          }
          return job;
        })
      };
      setOrderDetails(updatedList);  
    };
    const handleColorChange = (event, index, parentIndex) => {
      
      const { name, value } = event.target;
      setJobIndex(parentIndex); 
      const updatedList = {
        ...orderDetails,jobindex:parentIndex,
        jobList: orderDetails.jobList.map((job, i) => { 
          if (i === parentIndex) { 
            job.printingcolors[index][name] = value; 
          }
          return job;
        })
      };
      setOrderDetails(updatedList);  
    };
  
    const handleAddColor = (index, e, parentIndex) => {
       
       if(orderDetails.jobList[parentIndex].printingcolors.length < 8){
      const updatedList = {
        ...orderDetails,jobindex:parentIndex,
        jobList: orderDetails.jobList.map((job, i) => { 
          if (i === parentIndex) {
            return {
              ...job,
              printingcolors: [
                ...job.printingcolors,
                {
                  selectedColor: "",
                  colorhex: "",
                },
              ],
            };
          }
          return job;
        })
      };
      setOrderDetails(updatedList);  
      }else {
        toast.error("Only 8 colors can be added");
      }
    };
  
    const handleRemoveColor = (index, e, parentIndex) => {
      const updatedList = {
        ...orderDetails,
        jobList: orderDetails.jobList.map((job, i) => {
          if (i === parentIndex) {
            return {
              ...job,
              printingcolors: job.printingcolors.filter((color, j) => j !== index),
            };
          }
          return job;
        }),
      };
      setOrderDetails(updatedList);
    };
    
    const [orderDetails,setOrderDetails] = useState({
      clientName: "",
      orderStatus : "ORDER PUNCH",
      ordercreatedDate:new Date(),
      orderdateOfDeleivery: new Date(),
      orderNumber:(lsOrderLst !=null && lsOrderLst.length > 0) ? parseInt(lsOrderLst[lsOrderLst.length -1].orderNumber) +1 : 1,
      termsofdelivery:"Regular",
      expectedorderdeliverydate:moment().add(11,'days').format("DD-MM-yyyy"),
      customernumber:"",
      jobList:jobData,
      coa:true,
      transportername:"",
      deliverydestination:"",
      vendorcode:""
    })
    
    const handleMaterialChange = (event,i) => { 
      
      
      //setJobData({ ...jobData, selectedMaterial: event.target.value });
    };
    const handleMaterialTypeChange = (event) => { 
      
      setJobData({ ...jobData, selectedMaterial: event.target.value });
    };
        
  const user = useSelector((store) => store.user);
  useEffect(() => {
    
    if(typeof(orderDetails.jobindex) != "undefined")
      {calculateKGPC(orderDetails.jobindex);
      calculateextrusionnetweight(orderDetails.jobindex);
      calculateSheetMeter(orderDetails.jobindex);
    }
      if (!campaignToEditCalled && typeof state !== "undefined" && state !== null && typeof state.id !== "undefined") {
        setCampaignToEdit(state.id);
        setCampaignToEditCalled(true);
      }
    
    //GetTypeofMessages(); 
  },[orderDetails,jobIndex]);
  const setCampaignToEdit =(obj)  => {
    
    let dataObj = obj; 
    setOrderDetails(dataObj);
  }
 

  const handleSubmit = (e) => {
    
    if (e.key === 'Enter') {
      alert("enter press")
    }
    e.preventDefault();  

    if(orderDetails.orderStatus == "ORDER PUNCH"){ 
      orderDetails.orderStatus = "IN PLANNING";
      //localStorage.setItem('orderDetails', JSON.stringify(orderDetails));
    }else if(orderDetails.orderStatus == "IN PLANNING"){
      orderDetails.orderStatus = "IN EXTRUSION";
      //localStorage.setItem('orderDetails', JSON.stringify(orderDetails));
    }
    else if(orderDetails.orderStatus == "IN EXTRUSION"){
      if(typeof(orderDetails.jobList[0].printing_process) != "undefined"){
        if(orderDetails.jobList[0].printing_process == true){
          orderDetails.orderStatus = "IN PRINTING";
        }
      } else  {
        orderDetails.orderStatus = "IN PRINTING";
      }
      //localStorage.setItem('orderDetails', JSON.stringify(orderDetails));
    }
    //obj.push(orderDetails);
    //localStorage.setItem('orderDetails', JSON.stringify(orderDetails));    
    let OrderDetails = [];
    let storageOrder = JSON.parse(localStorage.getItem('orderDetails'));
    if(storageOrder!=null && storageOrder.length > 0){
      storageOrder.push(orderDetails);
      OrderDetails = storageOrder;
    }else{
      OrderDetails.push(orderDetails);
    }
    localStorage.setItem('orderDetails', JSON.stringify(OrderDetails));   
    toast.success("Your order has been created successfully");
    navigate("../manageOrder");
     
    //settempSubmitData(OrderDetails)
  }; 
 const handleSave = (e) =>{
  
  e.preventDefault();  
  orderDetails.orderStatus = "ORDER PUNCH"; 
    //obj.push(orderDetails);
    let OrderDetails = [];
    let storageOrder = JSON.parse(localStorage.getItem('orderDetails'));
    if(storageOrder!=null && storageOrder.length > 0){
      storageOrder.push(orderDetails);
      OrderDetails = storageOrder;
    }else{
      OrderDetails.push(orderDetails);
    }
    localStorage.setItem('orderDetails', JSON.stringify(OrderDetails));    
    toast.success("Your order has been created successfully");
    navigate("../manageOrder");
 };
const handleCancle = (e) =>{
  e.preventDefault();
   navigate("../manageOrder");
} 

const handleAddJobs = (index, e, parentIndex) =>{
   
   let job = {
    jobID:(index+1) + 1,
    id:"",
      width: "",
      length: "",
      gauge: "",
      micron: "",
      qtyType: "Kgs",
      priority: "",
      qtyValue: "",
      qtyValuepc:"",
      totalkgrequired:"",
      flapType: "OFT",
      flapSize: "",
      gusset: "Side",
      gussetSize: "",
      sealing: "Bottom",
      holePunchSize: "",
      holePunchNumber: "",
      cutType: "D-cut",
      weightPerBag: "",
      bagType: "plain",
      printedType: "",
      jobname: "",
      handleType: "",
      jobremark:"",
        TypeOfCut: "",
        Treatment: "",
        OD: false,
    
      jobStatus: "",
      productType: "",
      selectedMaterial:"" ,
      selectedType:"",
      jobCreatedDTS: new Date(),
      jobDeliverDTS: new Date(), 
      //extrussion_planning_datapoints
      extrusion_machine:0,
      tube:0,//formula
      tubeGauge:0,
      tubeGaugeMicron:0,
      extrusionKG:0,
      extrusionRemark:"",
      //extrusion_process_datapoints
      extrusionGrossWeight:0,
      extrusionCalculatedWeight:0,
      extrusionNetWeight:0,
      extrusionInMeter:0,
      extrusionGauge:0,
      extrusionQARemark:"",
      subbagType:"others",
      podwidth:0,
      podgauge:100,
      podsize:7,
      podcalcwt:0,
      selectedShift:"",
      multiplefactor:1,
      density:0.925,
      printingcolors:[
        {
          selectedColor: "",
          colorhex:"",
        }
      ],
      extroll:[
        {selectedShift :"",
          OperatorName:"",
          extrusionGrossWeight:0,
          extrusionCalculatedWeight:0,
          extrusionpackagingWeight:0,
          exrpm:"",
          extrusionNetWeight:0,
          extrusionGauge:0,
          extrusionInMeter:0,
          extrusionProductionInMeter:0,
          exwastage:0,
          extrusionQARemark:""}
      ],
      flapSizeYN:"No",
      gudetSiseYN:"No",
      holepunchSizeYN:"No",
      laminationDetails:[
        {qtylaminatekgs:0,qtylaminatemeter:0}
      ]
  }
  const updatedList = {
    ...orderDetails,
    jobList: [...orderDetails.jobList, job]
  }; 
  setOrderDetails(updatedList);
  // const newJobsCount = [...JobsCount, 0];
  // setJobsCount(newJobsCount); 
  
}
const handleRemoveJobs= (index, e, parentIndex) =>{
  
  var selectedIndex = index;
  const updatedList = {
    ...orderDetails,
    jobList: orderDetails.jobList.filter((job, index) => index !== selectedIndex)
  };
  setOrderDetails(updatedList);
}
const handleChangeProductType=(e)=>{
    e.preventDefault(); 
    setJobData({ ...jobData, productType: e.target.value });
    //setProductType(e.target.value);
}
const handleOrderChangeDetails = (e) => {
  
  e.preventDefault();
  if(e.target.name == "orderStatus"){
    if(e.target.value != "ORDER PUNCH"){
      setReadOnlyState(true)
    }else{
      setReadOnlyState(false)
    }
   
  }
  if(e.target.name == "termsofdelivery"){
    console.log(moment.version);
    const momentnow = moment();
    momentnow.add(e.target.value,'days');
    console.log(momentnow)
   setOrderDetails({ ...orderDetails, expectedorderdeliverydate:  momentnow.format("DD-MM-yyyy"), [e.target.name]: e.target.value });
  } 
  else{
    setOrderDetails({ ...orderDetails, [e.target.name]: e.target.value });
  }
}
const handleJobChangeCommaon = (index,e) => {  
  
  const { name, value } = e.target;
  var tempName = "";
  setJobIndex(index);
  let event = null;
  let  extraweight = 0;
  let lessweight =0;
  if(e.target.name.includes("bagType") || e.target.name.includes("printedType")){
    if(e.target.name.includes("subbagType")){
      tempName = name;
      if(value == "carry-bags"){
        if(orderDetails.jobList[index].cutType == "D-cut"){
          extraweight = 0.20; 
       }else if (orderDetails.jobList[index].cutType == "Polydraw bags"){
         extraweight = 2;
       }else if(orderDetails.jobList[index].cutType == "Loop handle"){
         extraweight = 4;
       }else if(orderDetails.jobList[index].cutType == "Patch handle"){
         extraweight = 2.67;
       }
      } 
    }else if(e.target.name.includes("bagType"))
      tempName = "bagType";
    else
      tempName = "printedType";
  }else{
    tempName = name;
  }
  if(e.target.name == "qtyValue" || e.target.name == "flapSize" ||e.target.name == "gussetSize" || e.target.name == "cutType" || e.target.name == "width" || e.target.name == "length"
  || e.target.name == "extrusionGrossWeight" || e.target.name == "extrusionCalculatedWeight" || e.target.name == "extrusionpackagingWeight" 
  || e.target.name == "sealing_process" || e.target.name == "dispatch_process" || e.target.name == "printing_process"
   || e.target.name == "lamination_process" || e.target.name == "slitting_process"|| e.target.name == "gudgeting_process"|| e.target.name == "cutting_process" 
|| e.target.name == "qtySheetValueKG" || e.target.name == "exqtyallowed"
  ){     
    event = e;
    if(value == "D-cut"){
       extraweight = 0.20; 
    }else if (value == "Polydraw bags"){
      extraweight = 2;
    }else if(value == "Loop handle"){
      extraweight = 4;
    }else if(value == "Patch handle"){
      extraweight = 2.67;
    }
   
  }
  
  //issues
  const updatedList = {
    ...orderDetails,jobindex:index,
    jobList: orderDetails.jobList.map((job, i) => {  
      if (i === index) {
        //console.log("event name : " + event + " name : " + name)
        if(name == "cutType" || value == "carry-bags"){ 
          return {
            ...job,
            [name]: value, 
            event:event,
            extraweight:extraweight,
            dcuttlessweight:lessweight
          };
        }else if( name == "sealing_process" || name == "dispatch_process" || name == "printing_process"
        ||name == "lamination_process" || name == "slitting_process"|| name == "gudgeting_process"|| name == "cutting_process"){
          return {
            ...job,
            [tempName]: event.target.checked,  
            dcuttlessweight:lessweight
            //extraweight:extraweight
          };
        }else if(name == "selectedMaterial"){
          let densitval=  getDensityValue(value); 
          return {
            ...job,
            [tempName]: value, 
            density:densitval,
            event:event,
            dcuttlessweight:lessweight
            //extraweight:extraweight
          };
        }
        else{
          return {
            ...job,
            [tempName]: value, 
            event:event,
            dcuttlessweight:lessweight
            //extraweight:extraweight
          };
        }
        
      }
      return job;
    })
  };
  setOrderDetails(updatedList); 
  
}

// const initialMaterialData = selectedLaminationMaterial.map((item) => ({
//   materialType: item,
//   qtyType: "Kgs", // Default qtyType
//   qtyValue: "", // Default qtyValue
// }));

function getLaminateFiled(materialtype,jobindex){
  let materialt = materialtype.split("+");
  
  let objmater = [];
  for(var i=0;materialt.length > i;i++){
    let a = {
      materialType: materialt[i],
      qtylaminatekgs: "0", // Default qtyType
      qtylaminatemeter: "0", // Default qtyValue
    }
    objmater.push(a);
  }
  // setselectedLaminationMaterial(objmater);
  // setJobIndex(jobindex); 
  // const updatedList = {
  //   ...orderDetails,jobindex:jobindex,
  //   jobList: orderDetails.jobList.map((job, i) => { 
  //     if (i === jobindex) { 
  //       job.laminationDetails=selectedLaminationMaterial
  //     }
  //     return job;
  //   })
  // };
  // setOrderDetails(updatedList);  
  return objmater;
}
// const handleJobChangeLaminateCommon = (index, event) => {
//   debugger
//   const { name, value } = event.target;
//   const updatedMaterialData = [...selectedLaminationMaterial];
//   updatedMaterialData[index][name] = value;
//   setselectedLaminationMaterial(updatedMaterialData);
// };
const handleJobChangeLaminateCommon = (event, index, parentIndex) => {
      debugger
  const { name, value } = event.target;
  setJobIndex(parentIndex); 
  const updatedList = {
    ...orderDetails,jobindex:parentIndex,
    jobList: orderDetails.jobList.map((job, i) => { 
      if (i === parentIndex) { 
        job.laminationDetails[index][name] = value;
        job.event=event;
        job.laminationRollIndex=index;
      }
      return job;
    })
  };
  setOrderDetails(updatedList);  
};
const handleJobChangeLaminationCommaon = (index,e) =>{
 debugger
    const { name, value } = e.target;
    var tempName = "";
    setJobIndex(index);
    let event = null;
    let  extraweight = 0;
    let lessweight =0;
    let laminateObj = getLaminateFiled(e.target.value,index);
    const updatedList = {
      ...orderDetails,jobindex:index,
      jobList: orderDetails.jobList.map((job, i) => {  
        if (i === index) {
           
          return {
            ...job,
            [name]: value, 
            event:event,
            //dcuttlessweight:lessweight
            //extraweight:extraweight
          };
        }
        return job;
      })
    };
    setOrderDetails(updatedList); 
    
}
 
const hadnleClientChange = (e) => {
  e.preventDefault();
  setClinetName(e.target.value);
} 
const [readOnlyState,setReadOnlyState] = useState(false);
const [clientName,setClinetName] = useState();
const [unit, setUnit] = useState("inches");

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };
const calculateMicronGauge = (index,e) =>{ 
    const { name, value } = e.target;
    
    let gaugevalue = "";
    let micronvalue = "";
    if(name == "micron"){
      micronvalue = value;
      gaugevalue = (value * 4);
    }else if(name == "gauge"){
      micronvalue = (value / 4);
      gaugevalue = value;
    }
    setJobIndex(index);
  const updatedList = {
    ...orderDetails,jobindex:index,
    jobList: orderDetails.jobList.map((job, i) => { 
      if (i === index) {
        return {
          ...job,
          event:e,
          "gauge": gaugevalue,
          "micron": micronvalue
        };
      }
      return job;
    })
  };
  setOrderDetails(updatedList);
};
const calculateSheetMeter = (index)=>{
  
  if(typeof(index) != "undefined"){
     
      if(orderDetails.jobList[index].event && orderDetails.jobList[index].event !=null){
        if(orderDetails.jobList[index].productType == "sheet" && orderDetails.jobList[index].subproducttype=="roll"){ 
          let qtySheetValueMetercalculated = parseFloat((orderDetails.jobList[index].qtySheetValueKG * 1000000)/((orderDetails.jobList[index].width) * 25.4 * (getDensityValue(orderDetails.jobList[index].selectedMaterial))  * (orderDetails.jobList[index].micron))).toFixed(2);
          // console.log("Quantity Sheet KG 1000000 " + orderDetails.jobList[index].qtySheetValueKG * 1000000 );
          // console.log("Divide orderDetails.jobList[index].width * 25.4 * " + parseFloat(orderDetails.jobList[index].width) * 25.4);
          // console.log(" * getDensityValue(orderDetails.jobList[index].selectedMaterial " +  parseFloat(orderDetails.jobList[index].micron));
          // console.log("* orderDetails.jobList[index].exqtyallowed/100 " +  parseFloat(orderDetails.jobList[index].exqtyallowed/100)).toFixed(2);
          //let extmeter = parseFloat(((netweight * 1000000)/((parseFloat(orderDetails.jobList[index].width) * 25.4)* getDensityValue(orderDetails.jobList[index].selectedMaterial) * parseFloat(orderDetails.jobList[index].micron))).toFixed(2));
          //let exgauge = (netweight * 1000000) / (orderDetails.jobList[index].width) * (getDensityValue(orderDetails.jobList[index].selectedMaterial)) * (extmeter);
      const updatedList = {
        ...orderDetails,jobindex:index,
        jobList: orderDetails.jobList.map((job, i) => { 
          if (i === index) { 
            return {
              ...job,
              "qtySheetValueMeter": qtySheetValueMetercalculated, 
              "event":null,  
            };
          }
          return job;
        })
      };
      setOrderDetails(updatedList);
        }
        
      }
}
}
const calculateextrusionnetweight = (index) =>{  
  if(typeof(index) != "undefined"){
    if(orderDetails.orderStatus == 'IN EXTRUSION'){
      if(orderDetails.jobList[index].event && orderDetails.jobList[index].event !=null){
        if(orderDetails.jobList[index].extrollindex != null){ 
          let rollindex = orderDetails.jobList[index].extrollindex;
          let netweight = parseFloat(orderDetails.jobList[index].extroll[rollindex].extrusionGrossWeight) - parseFloat(orderDetails.jobList[index].extroll[rollindex].extrusionpackagingWeight) - parseFloat(orderDetails.jobList[index].extroll[rollindex].extrusionCalculatedWeight);
          let extmeter = parseFloat(((netweight * 1000000)/((parseFloat(orderDetails.jobList[index].width) * 25.4)* getDensityValue(orderDetails.jobList[index].selectedMaterial) * parseFloat(orderDetails.jobList[index].micron))).toFixed(2));
          let exgauge = (netweight * 1000000) / (orderDetails.jobList[index].width) * (getDensityValue(orderDetails.jobList[index].selectedMaterial)) * (extmeter);
      const updatedList = {
        ...orderDetails,jobindex:index,
        jobList: orderDetails.jobList.map((job, i) => { 
          if (i === index) { 
            return {
              ...job,
              "extrusionNetWeight": netweight, 
              "event":null, 
              "extrusionInMeter":extmeter,
              "extrusionGauge":exgauge
            };
          }
          return job;
        })
      };
      setOrderDetails(updatedList);
        }
        
      }
    }
}
};
function getDensityValue(materialtype){
  let value = 0;
  if(materialtype == "LDNatural" || materialtype == "LDMilky" || materialtype == "LDShrink"){
value = 0.925;
  }else if(materialtype == "HM"){
    value = 0.950;
  }
  else if(materialtype == "PP"){
    value = 0.91;
  }else if(materialtype == "BOPP"){
    value = 0.903;

  }
  return value;
}
const calculateExWeight = (index,e) =>{ 
  
  const { name, value } = e.target;
  let exweight = ""; 
   
  setJobIndex(index);
const updatedList = {
  ...orderDetails,jobindex:index,
  jobList: orderDetails.jobList.map((job, i) => { 
    if (i === index) {
      var percentage = ((parseFloat(orderDetails.jobList[index].totalkgrequired) * value) / 100);
      let exweight =  percentage + parseFloat(orderDetails.jobList[index].totalkgrequired);
      return {
        ...job,
        "wastepercentage":value,
        "extrusionKG": exweight.toFixed(2)
      };
    }
    return job;
  })
};
setOrderDetails(updatedList);
};
const handleLaminateValueChange =(index,e)=>{

}
const calculateTubeMicronGauge = (index,e) =>{ 
  const { name, value } = e.target;
  let gaugevalue = "";
  let micronvalue = "";
  if(name == "tubeGaugeMicron"){
    micronvalue = value;
    gaugevalue = (value * 4);
  }else if(name == "tubeGauge"){
    micronvalue = (value / 4);
    gaugevalue = value;
  }
  setJobIndex(index);
const updatedList = {
  ...orderDetails,jobindex:index,
  jobList: orderDetails.jobList.map((job, i) => { 
    if (i === index) {
      return {
        ...job,
        "tubeGaugeMicron":micronvalue ,
        "tubeGauge": gaugevalue
      };
    }
    return job;
  })
};
setOrderDetails(updatedList);
};
function CalculatePerKG(index,e,name){
  
  let length =  parseFloat(orderDetails.jobList[index].length);
  let width = parseFloat(orderDetails.jobList[index].width);
  if(unit == "mm"){
    length = length / 25.4;
    width = width / 25.4;
  }else{
    length = length;
    width = width;
  }
  
  let gussetSize = 0;
  let flapSize = 0;
  if(orderDetails.jobList[index].gussetSize > 0 ){
    gussetSize = parseFloat(orderDetails.jobList[index].gussetSize);
    console.log("gussetSize" + gussetSize);
  }
  if(orderDetails.jobList[index].flapSize > 0 ){
    flapSize =parseFloat(orderDetails.jobList[index].flapSize);
    console.log("flapSize" + flapSize);
  }
  //if(typeof(name) != "undefined" && name != ""){ 
    if(orderDetails.jobList[index].flapType == "OF" || orderDetails.jobList[index].flapType == "OFT"){
      
      if(orderDetails.jobList[index].gusset == "Bottom"){
        console.log("Bottom :");
        console.log("length " + length);
        console.log("Flap Size" + + ((flapSize/ 2)));
        console.log("bottom output" +  parseFloat(length) + ((flapSize/ 2) + (gussetSize)))
        length = parseFloat(length) + ((flapSize/ 2) + (gussetSize));
      }else if(orderDetails.jobList[index].gusset == "Side"){ 
        if(gussetSize > 0){
          width = parseFloat(width) + (gussetSize + gussetSize);
          console.log("Side " + parseFloat(width));
        }
        if(flapSize > 0){
          console.log("only flap tap" + length + (flapSize/ 2));
          length = parseFloat(length) + (flapSize/ 2);
        }
       
      }else if(flapSize > 0){
        console.log("only flap " + length + (flapSize/ 2));
        length = parseFloat(length) + (flapSize/ 2);
      }
    
    }
 // }

  let perkg =   (((parseFloat(width) * parseFloat(length)) * (parseFloat(orderDetails.jobList[index].gauge))) / 3300); 
  console.log(("per kg width * length" + (parseFloat(width) * parseFloat(length))));
  if(orderDetails.jobList[index].subbagType == "carry-bags"){ 
    //if d-cut subtract
    console.log("per kg " + perkg + orderDetails.jobList[index].extraweight + " Extra weight" + orderDetails.jobList[index].extraweight);
    if(orderDetails.jobList[index].cutType == "D-cut"){
      perkg = (perkg) - (orderDetails.jobList[index].extraweight); 
    }else{
      perkg = (perkg) + (orderDetails.jobList[index].extraweight); 
    }
   
    }
  return perkg;
}
const calculateKGPC = (index) =>{  
  
  if(orderDetails.orderStatus == 'ORDER PUNCH'){
  if(orderDetails.jobList[index].event && orderDetails.jobList[index].event !=null){
   
    let e = orderDetails.jobList[index];
  //const { name, value } = e.target;
  let qpcs = 0;
  let qkgs = 0;
  let tkgs = 0;
  tkgs ="";
  let o =CalculatePerKG(index,e);
 //console.log("Caluclate per kg" + o);
  if(orderDetails.jobList[index].qtyType == "Kgs"){
    qpcs = ((parseFloat(orderDetails.jobList[index].qtyValue) * 1000)/o);
    //console.log("qtvalue kg : " + orderDetails.jobList[index].qtyValue);
  }else if(orderDetails.jobList[index].qtyType == "Pcs"){ 
    qpcs = parseFloat(orderDetails.jobList[index].qtyValue);
    //console.log("qtvalue pcs : " + orderDetails.jobList[index].qtyValue);
  }
  //console.log("o : " + o);
  tkgs = ((o * qpcs)/1000).toFixed(2); 
  //console.log("totalkgrequired 1 :" + tkgs);
  if(orderDetails.jobList[index].subbagType == "courier-bags-pod"){
    
    var podwt= ((parseFloat(orderDetails.jobList[index].width) * parseFloat(orderDetails.jobList[index].podgauge) * parseFloat(orderDetails.jobList[index].podsize)) / 3300) /2;
    orderDetails.jobList[index].podcalcwt = podwt.toFixed(2);
  }
  if(orderDetails.jobList[index].cutType=="D-cut"){
    orderDetails.jobList[index].dcuttlessweight = parseFloat((0.75 * 3.5 * orderDetails.jobList[index].gauge)/ 3300).toFixed(2);
  }else{
    orderDetails.jobList[index].dcuttlessweight = 0
  }

const updatedList = {
  ...orderDetails,jobindex:index,
  jobList: orderDetails.jobList.map((job, i) => { 
    if (i === index) {
      
      console.log("totalkgrequired 2 :" + tkgs);
      if(job.productType == "sheet" && job.subproducttype == "cutting"){
      return {
        ...job,
        "qtyValue": orderDetails.jobList[index].qtyValue,
        "qtyValuepc": qpcs.toFixed(2),
        "totalkgrequired":tkgs,
        "event":null, 
        weightPerBag:o.toFixed(2)/2,
        podcalcwt: orderDetails.jobList[index].podcalcwt,
        dcuttlessweight: orderDetails.jobList[index].dcuttlessweight
      }
      }
      else{
        return {
          ...job,
          "qtyValue": orderDetails.jobList[index].qtyValue,
          "qtyValuepc": qpcs.toFixed(2),
          "totalkgrequired":tkgs,
          "event":null,
          weightPerBag:o.toFixed(2),
          podcalcwt: orderDetails.jobList[index].podcalcwt,
          dcuttlessweight: orderDetails.jobList[index].dcuttlessweight
        };
      }
    }
    return job;
  })
  
};
setOrderDetails(updatedList);
  }
  }
};
const handleCOACheckChange = (event) => {
  const { name, checked } = event.target;
  setOrderDetails((prevOrderDetails) => ({
    ...prevOrderDetails,
    [name]: checked
  }));
};
const handleExtRoll =(event) =>{

};
  return (
    <>
      <HeadContainer title={(orderDetails.orderStatus == "ORDER PUNCH") ? "Create Order" : "Order is in " + orderDetails.orderStatus} buttonObj={[]} />
      <GBMCampaignWrapper>
        <div className="row">
          <div className="col-12">
            <form className="form-container" onSubmit={handleSubmit} name="form">
              <div className="createCampaignWpr">
                <div className="dflexstart commonInputBtnWpr">
                  { 
                      <section>
                          <div className="row">
                              <div className="col-2">
                                  <FormRow name={"orderNumber"} handleChange={handleOrderChangeDetails} 
                                  labelText={"Order number"} type={"number"} mandatory={true} 
                                  errorobj={""} value={orderDetails.orderNumber} readOnly={readOnlyState}/>
                              </div> 
                              
                              <div className="col-2">
                                  <FormRow name={"vendorcode"} handleChange={handleOrderChangeDetails} 
                                  labelText={"Vendor Code"} type={"text"} mandatory={true} 
                                  errorobj={""} value={orderDetails.vendorcode} readOnly={readOnlyState}/>
                              </div> 
                              <div className="col-3">
                                  <FormRow name={"customernumber"} handleChange={handleOrderChangeDetails} 
                                  labelText={"Customer order number"} type={"text"} mandatory={true} 
                                  errorobj={""} value={orderDetails.customernumber} readOnly={readOnlyState}/>
                              </div> 
                              <div className="col-3">
                                  <label>Select Customer Name</label>
                                  <select  readOnly={readOnlyState} name="clientName" onChange={handleOrderChangeDetails} value={orderDetails.clientName}>
                                      <option>Donear Industries Limited (Unit Balaji Fabr)</option>
                                      <option>Georg Fischer Piping Systems Pvt. Ltd.</option>
                                      <option>Jamnagar Wollen Textile Mills P. Ltd.</option>
                                      <option>Kewal Kiran Clothing Ltd.</option>
                                      <option>M. B. Sugars & Pharmaceuticals Pvt Ltd.</option>
                                  </select>
                              </div> 
                              <div className="col-2">
                                  <label>Terms of Delivery</label>
                                  <select  readOnly={readOnlyState} name="termsofdelivery" onChange={handleOrderChangeDetails} value={orderDetails.termsofdelivery}>
                                      <option value="11">Regular</option>
                                      <option value="4">Urgent</option>
                                      <option value="10">Immediate</option> 
                                  </select>
                              </div> 
                            
                              <div className="col-2">
                                  <label htmlFor="unit">Select Unit</label>
                                  <select id="unit" value={unit} onChange={handleUnitChange}>
                                    <option value="inches">Inches</option>
                                    <option value="mm">Millimeters</option>
                                  </select> 
                              </div> 
                              
                            <div className="col-3 displaynone">
                                <label>Order Status</label> 
                                  <select className="form-control" name="orderStatus" onChange={(evnt) => handleOrderChangeDetails(evnt)}  value={orderDetails.orderStatus}>   
                                      <option id="1" value="ORDER PUNCH">ORDER PUNCH</option>
                                      <option id="2" value="IN PLANNING">IN PLANNING</option>
                                      <option id="3" value="IN EXTRUSION">IN EXTRUSION</option>
                                      <option id="4" value="IN PRINTING">IN PRINTING</option>
                                      <option id="5" value="IN LAMINATION">IN LAMINATION</option>
                                      <option id="6" value="IN GUDGETING & SLITING">IN GUDGETING & SLITING</option>
                                      <option id="7" value="IN CUTTING & SEALING">IN CUTTING & SEALING</option> 
                                  </select>   
                            </div>
                            <div className="col-3">
                            <FormRow name={"transportername"} 
                                          handleChange={handleOrderChangeDetails} 
                                          labelText={"Transporter Name"} 
                                          value={orderDetails.transportername} type={"text"} 
                                          mandatory={true}  errorobj={""} />      
                            </div>
                            <div className="col-3">
                            <FormRow name={"deliverydestination"} 
                                          handleChange={handleOrderChangeDetails} 
                                          labelText={"Delivery Destination"} 
                                          value={orderDetails.deliverydestination} type={"text"} 
                                          mandatory={true}  errorobj={""} />      
                            </div>
                            <div className="col-2">
                              <label>Expected Delivery Date </label>
                                <label><b>{orderDetails.expectedorderdeliverydate} {(orderDetails.termsofdelivery > 10 ? " plus" : "")}</b></label>
                              </div> 
                            <div className="col-12"> 
                              <label htmlFor="coa"><input type="checkbox"   className="mr-5" name="coa" onChange={handleCOACheckChange} checked={orderDetails.coa} /> <b>COA required</b></label>
                            </div>
                          </div>
                          {orderDetails.jobList.map((x,i) => (
                              <> 
                               <section className="innternal_container"> 
                               {(orderDetails.orderStatus != "ORDER PUNCH") ? 
                               
                                  <div className="row orderpreview">
                                     
                                    <div className="col-2">
                                      <label>Sr. No</label>
                                      <p>{x.jobID}</p>
                                    </div>
                                  {(x.productType == "laminatedpouch" || x.productType == "laminatedroll") ? 
                                  <>
                                   {(x.laminationDetails.map((item,index)=>(
                                     <> 
                                     <div className="col-2">
                                      <label>Material</label>
                                      <p>{item.MaterialType}</p>
                                    </div>
                                    <div className="col-2">
                                      <label>Quantity</label>
                                      <p>KGs - {item.qtylaminatekgs} | Meter - {item.qtylaminatemeter}</p>
                                    </div>
                                   </>
                                   )))} 
                                   
                                    {(x.productType == "laminatedpouch") ?
                             <div className="col-2">
                             <label>Weight per Pouch</label>
                             <p>{x.weighperpouch}</p>
                           </div> : ""
                            }
                              {(x.productType == "laminatedroll") ? 
                              <div className="col-2">
                              <label>Weight per Pouch</label>
                              <p>{x.weighperrolllamination}</p>
                            </div> : ""
                            }
                                  </> 
                                  : 
                                  <>
                                    <div className="col-2">
                                      <label>Material</label>
                                      <p>{x.selectedMaterial}</p>
                                    </div>
                                    <div className="col-2">
                                      <label>Density</label>
                                      <p>{x.density}</p>
                                    </div>
                                    <div className="col-2">
                                      <label>Product</label>
                                      <p>{x.productType}</p>
                                    </div>
                                    <div className="col-2">
                                      <label>Sealing</label>
                                      <p>{x.sealing}</p>
                                    </div>
                                    <div className="col-2">
                                      <label>Type</label>
                                      <p>{x.bagType}</p>
                                    </div> 
                                    <div className="col-12">
                                      <label>Speciality</label>
                                      <p>
                                      {x.flapType !="" ? <span>Flap Type - <b className="boldcount">{x.flapType}</b></span>  : ""}
                                      {x.flapSize > 0 ?  <span>Flap Size - <b className="boldcount">{x.flapSize}</b> </span>  : ""}
                                      {x.gussetSize > 0 ?   <span>Gudget - <b className="boldcount">{x.gussetSize} </b></span>   : ""}
                                      {x.holePunchNumber > 0 ?   <span>Number of Hole Punch - <b className="boldcount">{x.holePunchNumber} </b></span>  : ""}
                                      {/* {x.cutType > 0 ?   <span className="right-side">Cut Type -<b className="boldcount"  >{x.cutType}</b></span>   : ""} */}
                                     </p>
                                    </div>
                                    <div className="col-1">
                                      <label>Width</label>
                                      <p>{x.width}</p>
                                    </div>
                                    <div className="col-1">
                                      <label>Length</label>
                                      <p>{x.length}</p>
                                    </div>
                                    <div className="col-1">
                                      <label>Micron</label>
                                      <p>{x.micron}</p>
                                    </div>
                                    <div className="col-1">
                                      <label>Gauge</label>
                                      <p>{x.gauge}</p>
                                    </div>
                                    
                                    {/* <div className="col-2">
                                      <label>Quantity Type</label>
                                      <p>{x.qtyType}</p>
                                    </div> */}
                                    <div className="col-2">
                                      <label>Quantity</label>
                                      <p>KGs - {x.qtyValue} | PCs - {x.qtyValuepc}</p>
                                    </div> 
                                    {x.weightPerBag > 0 ? 
                                    
                                    <div className="col-2">
                                      <label>Weight Per {(x.productType == 'bag') ?  "bag" : "sheet"}</label>
                                      <p>{x.weightPerBag}</p>
                                    </div>
                                    : ""}
                                    </>
                                    }
                                    {(x.productType == "sheet" && x.subproducttype == "roll") || (x.productType == "tubing") ?
                                    <div className="col-2">
                                      <label>Roll Quantity</label>
                                      <p>{x.rollQty}</p>
                                    </div> : ""
                                    }
                                    <div className="col-2">
                                      <label>Excess Qty Allowed</label>
                                      <p>{x.exqtyallowed}</p>
                                    </div>
                                    <div className="col-8">
                                      <label>Job Remark</label>
                                      <p>{(x.jobremark && x.jobremark.length > 0 ? x.jobremark : "NA")}</p>
                                    </div> 
                                  <div>

                                    <p>  
                                    {x.handleType != "" ? <span>Handle Type  -<b className="boldcount">{x.handleType}</b></span>   : ""}
                                    {x.jobname != "" ? <span className="right-side">jobname  -<b className="boldcount">{x.jobname}</b> </span>  : ""}</p>

                                    
                                  </div>
                                    </div>
                               
                              : ""}
                               {(orderDetails.orderStatus == "IN PLANNING") ? 
                                  <section className="">
                                    <div className="row sub-sub-containers">
                                      <h4>Details to be entered when order is in Planning</h4>
                                       
                                        <div className="col-2">
                                          <label>Select Process</label>
                                          <select>
                                            <option></option>
                                          </select>
                                        </div>
                                        <div className="col-1">
                                              <label>Treatment</label>
                                              <select className="form-control" name="planning_treatment" 
                                                  onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                                  value={x.planning_treatment}>  
                                                    <option id="0" value="BST">BST</option>
                                                  <option id="1" value="PL">PL</option>
                                                  <option id="2" value="OST">OST</option>
                                                  <option id="3" value="Online Printing">Online Printing</option>
                                                 
                                              </select>           
                                        </div>
                                       
                                      {/* <div className="row">
                                      <div className="col-2">
      <label>
        <input
          className="mr-5"
          type="checkbox" 
          checked={x.printing_process}
          name="printing_process"
          onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
        />
        Printing
      </label>
      </div>
      <div className="col-2">
      <label>
        <input
          type="checkbox"
          className="mr-5"
          name="lamination_process"
          checked={x.lamination_process}
          onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
        />
        Lamination
      </label>
</div>
<div className="col-2">
      <label>
        <input
          type="checkbox"
          className="mr-5"
          name="slitting_process"
          checked={x.slitting_process}
          onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
        />
        Slitting
      </label>
</div>
<div className="col-2">
      <label>
        <input
          type="checkbox"
          className="mr-5"
          name="gudgeting_process"
          checked={x.gudgeting_process}
          onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
        />
        Gudgeting
      </label>
</div>
<div className="col-2">
      <label>
        <input
          type="checkbox"
          className="mr-5"
          name="cutting_process"
          checked={x.cutting_process}
          onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
        />
        Cutting & Sealing
      </label>
</div> 
<div className="col-2">
      <label>
        <input
          type="checkbox"
          className="mr-5"
          name="dispatch_process" 
          checked={x.dispatch_process}
          onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
        />
        Dispatch
      </label>
    </div>
                                      </div> */}
                                        <div className="col-2"> 
                                    <label>Tube Size</label>
                                      <p><b>{(x.sealing == "Side") ? x.length : x.width}</b></p>
                                                                   
                                          </div> 
                                      <div className="col-2"> 
                                    <label>Order Tube</label>
                                      <p><b>{(x.sealing == "Side") ? x.length : x.width}</b></p>
                                                                   
                                          </div> 
                                        
                                          <div className="col-2"> 
                                    <label>Flap Size from Sales</label>
                                      <p><b>{x.flapSize}</b></p>
                                                                   
                                          </div> 
                                          <div className="col-2"> 
                                    <label>Gudget from Sales</label>
                                      <p><b>{x.flapSize}</b></p>
                                                                   
                                          </div> 
                                          <div className="col-2"> 
                                            <FormRow name={"multiplefactor"} 
                                          handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                            labelText={"multiple factor"} 
                                            value={x.multiplefactor} type={"text"} 
                                            mandatory={true}  errorobj={""} />                               
                                          </div>
                                          <div className="col-2"> 
                                            <FormRow name={"planningtube"} 
                                            handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                            labelText={"Planning Tube"} 
                                            value={x.planningtube} type={"text"} 
                                            mandatory={true}  errorobj={""} />                               
                                          </div> 
                                          <div className="col-2"> 
                                            <FormRow name={"treaming"} 
                                            handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                            labelText={"Treaming Tube"} 
                                            value={x.treaming} type={"text"} 
                                            mandatory={true}  errorobj={""} />                               
                                          </div> 
                                          <div className="col-2"> 
                                            <FormRow name={"tube"} 
                                            handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                            labelText={"Production Tube"} 
                                            value={x.tube} type={"text"} 
                                            mandatory={true}  errorobj={""} />                               
                                          </div>  
                                          <div className="col-2">
                                              <label>Multifactor Type</label>
                                              <select className="form-control" name="mlt_type" 
                                                  onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                                  value={x.mlt_type}>  
                                                    <option id="0" value="BSC">BSC</option>
                                                  <option id="1" value="OSC">OSC</option>
                                                   
                                                 
                                              </select>           
                                        </div>
                                        <div className="col-2">
                            <FormRow name={"prodtubeGaugeMicron"}handleChange={(evnt) => calculateTubeMicronGauge(i, evnt)} labelText={"Prod Tube Micron"} value={x.prodtubeGaugeMicron} type={"text"} mandatory={true}  errorobj={""} />
                            </div>
                            <div className="col-2">
                            <FormRow name={"prodtubeGauge"}handleChange={(evnt) => calculateTubeMicronGauge(i, evnt)} labelText={"Prod Tube Gauge"} value={x.prodtubeGauge} type={"text"} mandatory={true}  errorobj={""} />
                            </div>
                                        <div className="col-1">
                                              <label>EX MC</label>
                                              <select className="form-control" name="extrusion_machine" 
                                                  onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                                  value={x.extrusion_machine}>  
                                                    <option id="0" value="0">0</option>
                                                  <option id="1" value="1">1</option>
                                                  <option id="2" value="2">2</option>
                                                  <option id="3" value="3">3</option>
                                                  <option id="4" value="4">4</option>
                                                  <option id="5" value="5">5</option>
                                                  <option id="6" value="6">6</option>
                                                  <option id="7" value="7">7</option>
                                                  <option id="8" value="8">8</option>
                                                  <option id="9" value="9">9</option>
                                              </select>           
                                        </div>
                                       
                                        <div className="col-1"> 
                                          <FormRow name={"extrusionSerial"} 
                                          handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                          labelText={"EX SR"} 
                                          value={x.extrusionSerial} type={"text"} 
                                          mandatory={true}  errorobj={""} />                               
                                        </div>
                                        <div className="col-1"> 
                                          <FormRow name={"perrollmtr"} 
                                          handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                          labelText={"Per roll MTR"} 
                                          value={x.perrollmtr} type={"text"} 
                                          mandatory={true}  errorobj={""} />                               
                                        </div>
                                        <div className="col-1"> 
                                          <FormRow name={"perrollkg"} 
                                          handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                          labelText={"Per roll kg"} 
                                          value={x.perrollkg} type={"text"} 
                                          mandatory={true}  errorobj={""} />                               
                                        </div>
                                        <div className="col-1">
                                              <label>Roll Placement</label>
                                              <select className="form-control" name="rollplacement" 
                                                  onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                                  value={x.rollplacement}>  
                                                    <option id="EX" value="EX">EX</option>
                                                  <option id="CS" value="CS">CS</option>
                                                  <option id="PR" value="PR">PR</option>
                                                
                                              </select>           
                                        </div>
                                        <div className="col-2"> 
                                            <FormRow name={"wastepercentage"} 
                                            handleChange={(evnt) => calculateExWeight(i, evnt)} 
                                            labelText={"Wastage %"} 
                                            value={x.wastepercentage} type={"text"} 
                                            mandatory={true}  errorobj={""} />                               
                                          </div>
                                          <div className="col-1">  
                                                    <label>EX (KG)</label>
                                                    <p>{x.extrusionKG}</p>            
                                          </div> 
                                         
                                          <div className ="col-4">
                                            <FormRow name={"extrusionmeterroll"} 
                                            handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                            labelText={"Supply expected Roll Size"} 
                                            value={x.extrusionmeterroll} type={"text"} 
                                            mandatory={true}  errorobj={""} />     
                                          </div>
                                         
                                          <div className ="col-8">
                                            <FormRow name={"extrusionRemark"} 
                                            handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                            labelText={"EX Remark"} 
                                            value={x.extrusionRemark} type={"textarea"} 
                                            mandatory={true}  errorobj={""} />     
                                          </div>
                                          
                                    </div>
                                  </section>
                                : ""}
                                {(orderDetails.orderStatus == "IN EXTRUSION") ? 
                                  <section className="orderpreview">
                                      <div className="row"> 
                                        <div className="col-1">
                                            <label>EX MC</label>
											                      <p>{x.extrusion_machine}</p>   
                                        </div>
                                        <div className="col-1"> 
										                        <label>EX SR</label>
											                      <p>{x.extrusionSerial}</p>                         
                                        </div>
                                         
                                          <div className="col-1"> 
                                            <label>Tube Gauge</label>
                                            <p>{x.tubeGauge}</p>                               
                                          </div>
                                          <div className="col-1"> 
                                            <label>EX (KG)</label>
                                            <p>{x.extrusionKG}</p>                       
                                          </div>
                                          <div className="col-1"> 
                                          <label>Order Tube</label>
                                            <p>{x.ordertube}</p>         
                                                                         
                                        </div>
                                          <div className="col-1"> 
                                            <label>EX Wastage</label>
                                            <p>{x.wastepercentage}</p>                       
                                          </div>
                                          <div className="col-2"> 
                                            <label>Production Tube</label>
                                            <p>{x.tube}</p>                             
                                          </div>
                                          <div className="col-2"> 
                                            <label>EX Roll in meter</label>
                                            <p>{x.extrusionmeterroll}</p>                       
                                          </div>
                                         
                                          <div className ="col-3">  
                                            <label>QA Remark </label>
                                            <p>{x.extrusionRemark}</p>
                                          </div>
                                      </div>
								                    	<div className="row sub-sub-containers">
                                        <h4>Details to be entered when order is in Extrusion 
                                            {/* <div className="col-2"> */}
                                              <button id="add" type="button" className="btn btn-primary" onClick={(evnt) => handleExtRoll(i, evnt)}>
                                                Add Rolls
                                              </button>
                                          {/* </div> */}
                                        </h4>
                                        {x.extroll.map((y,rollindex)=>(
                                          <>
                                          
                                           <div className="col-1">
                                           <label >
                                             <input
                                               type="checkbox"
                                               value="Shift 1"
                                                name="selectedShift"
                                                checked={(y.selectedShift == "Shift 1") ? true : false}
                                                onChange={(event) => handlerolechange(event,rollindex,i)}
                                             />
                                             Shift 1
                                           </label>
                                           </div>
                                           <div className="col-1">
                                           <label >
                                             <input
                                               type="checkbox"
                                               value="Shift 2"
                                               name="selectedShift"
                                               checked={(y.selectedShift == "Shift 2") ? true : false}
                                               onChange={(event) => handlerolechange(event,rollindex,i)}
                                             />
                                             Shift 2
                                           </label>
                                           </div>
                                           
                                           <div className="col-2">
                                             <FormRow name={"OperatorName"} 
                                             handleChange={(event) => handlerolechange(event,rollindex,i)}
                                             labelText={"OperatorName"} 
                                             value={y.OperatorName} type={"text"} 
                                             mandatory={true}  errorobj={""} /> 
                                           </div>
                                          
                                           <div className="col-2">
                                             <FormRow name={"extrusionGrossWeight"} 
                                             handleChange={(event) => handlerolechange(event,rollindex,i)}
                                             labelText={"EX GW"} 
                                             value={y.extrusionGrossWeight} type={"text"} 
                                             mandatory={true}  errorobj={""} /> 
                                           </div>
                                           <div className="col-2"> 
                                             <FormRow name={"extrusionCalculatedWeight"} 
                                             handleChange={(event) => handlerolechange(event,rollindex,i)}
                                             labelText={"EX CW"} 
                                             value={y.extrusionCalculatedWeight} type={"text"} 
                                             mandatory={true}  errorobj={""} />                               
                                           </div>
                                           <div className="col-2">
                                             <FormRow name={"extrusionpackagingWeight"} 
                                               handleChange={(event) => handlerolechange(event,rollindex,i)}
                                               labelText={"EX PW"} 
                                               value={y.extrusionpackagingWeight} type={"text"} 
                                               mandatory={true}  errorobj={""} /> 
                                           </div>
                                           <div className="col-2">
                                             <FormRow name={"exrpm"} 
                                             handleChange={(event) => handlerolechange(event,rollindex,i)}
                                             labelText={"EX RPM"} 
                                             value={y.exrpm} type={"text"} 
                                             mandatory={true}  errorobj={""} /> 
                                           </div>
                                           <div className="col-2">  
                                              <label>EX NW (KG)</label>
                                               <p>{y.extrusionNetWeight}</p>                                
                                           </div>
                                           <div className="col-2">  
                                              <label>EX Gauge (KG)</label>
                                               <p>{y.extrusionGauge}</p>                                
                                           </div>
                                           
                                           <div className="col-2"> 
                                                <FormRow name={"extrusionInMeter"} 
                                             handleChange={(event) => handlerolechange(event,rollindex,i)}
                                             labelText={"Calculation Meter"} 
                                             value={y.extrusionInMeter} type={"text"} 
                                             mandatory={true}  errorobj={""} />                                
                                           </div>
                                           <div className="col-2"> 
                                                <FormRow name={"extrusionProductionInMeter"} 
                                             handleChange={(event) => handlerolechange(event,rollindex,i)}
                                             labelText={"EX Production Meter"} 
                                             value={y.extrusionProductionInMeter} type={"text"} 
                                             mandatory={true}  errorobj={""} />                                
                                           </div>
                                           <div className="col-2"> 
                                               <FormRow name={"exwastage"} 
                                               handleChange={(event) => handlerolechange(event,rollindex,i)}
                                               labelText={"Extrusion Wastage "} 
                                               value={y.exwastage} type={"text"} 
                                               mandatory={true}  errorobj={""} />                               
                                             </div> 
                                           <div className ="col-10">
                                               <FormRow name={"extrusionQARemark"} 
                                             handleChange={(event) => handlerolechange(event,rollindex,i)} 
                                             labelText={"EX QA Remark"} 
                                             value={y.extrusionQARemark} type={"textarea"} 
                                             mandatory={true}  errorobj={""} />      
                                           </div>
                                           </>
                                        ))}
                                       
                                        
                                    </div>
                                  </section>
                                : ""}
                                 {(orderDetails.orderStatus == "IN PRINTING") ? 
                                  <section className="orderpreview">
                                      <div className="row">
                                        <div className="col-1">
                                            <label>EX MC</label>
											                      <p>{x.extrusion_machine}</p>   
                                        </div>
                                        <div className="col-1"> 
										                        <label>EX SR</label>
											                      <p>{x.extrusionSerial}</p>                         
                                        </div>
                                         
                                          <div className="col-1"> 
                                            <label>Tube Gauge</label>
                                            <p>{x.tubeGauge}</p>                               
                                          </div>
                                          <div className="col-1"> 
                                            <label>EX (KG)</label>
                                            <p>{x.extrusionKG}</p>                       
                                          </div>
                                        
                                         
                                          <div className ="col-1">  
                                            <label>EX GW</label>
                                            <p>{x.extrusionGrossWeight}</p>
                                          </div>
                                          <div className ="col-1">  
                                            <label>EX CW</label>
                                            <p>{x.extrusionCalculatedWeight}</p>
                                          </div>
                                          <div className ="col-1">  
                                            <label>EX PW</label>
                                            <p>{x.extrusionpackagingWeight}</p>
                                          </div>
                                          <div className ="col-1">  
                                            <label>EX NW (KG)</label>
                                            <p>{x.extrusionNetWeight}</p>
                                          </div>
                                          <div className ="col-1">  
                                            <label>EX Gauge (KG)</label>
                                            <p>{x.extrusionGauge}</p>
                                          </div>
                                          <div className="col-2">  
                                           <label>Order Tube</label>
											                      <p>{x.exordertube}</p>                                
                                        </div>
                                          <div className="col-1"> 
                                            <label>Production Tube</label>
                                            <p>{x.tube}</p>                             
                                          </div>
                                          <div className="col-2"> 
                                            <label>EX Roll in meter</label>
                                            <p>{x.extrusionmeterroll}</p>                       
                                          </div>
                                          <div className="col-2">  
                                           <label>Calculation Meter</label>
											                      <p>{x.extrusionInMeter}</p>                                
                                        </div>
                                        <div className="col-2">  
                                           <label>EX Production Meter</label>
											                      <p>{x.extrusionProductionInMeter}</p>                                
                                        </div>
                                       
                                        <div className ="col-6">  
                                            <label>QA Remark </label>
                                            <p>{x.extrusionRemark}</p>
                                          </div>
                                        <div className ="col-6">
                                          <label>EX QA Remark</label>
											                      <p>{x.extrusionQARemark}</p>  
                                        </div>
                                      </div>
								                    	<div className="row sub-sub-containers">
                                        <h4>Details to be entered when order is in Planning</h4>  
                                        <div className="col-1">
                                              <label>PR MC</label> 
                                          <select className="form-control" name="printingMC" 
                                                  onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                                  value={x.printingMC}>  
                                                    <option id="0" value="0">0</option>
                                                  <option id="1" value="1">1</option>
                                                  <option id="2" value="2">2</option>
                                                  <option id="3" value="3">3</option>
                                                  <option id="4" value="4">4</option>
                                                  <option id="5" value="5">5</option>
                                                  <option id="6" value="6">6</option>
                                                  <option id="7" value="7">7</option>
                                                  <option id="8" value="8">8</option>
                                                  <option id="9" value="9">9</option>
                                              </select>    
                                        </div>
                                        <div className="col-1"> 
                                          <FormRow name={"printingSR"} 
                                          handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                          labelText={"Printing SR"} 
                                          value={x.printingSR} type={"text"} 
                                          mandatory={true}  errorobj={""} />                               
                                        </div>
                                        <div className="col-1">
                                          <label>Printing INK</label>
                                          <p>
                                          {x.printingcolors.map((color, index) => (
                                           
                                            <span key={index} className="color" style={{ backgroundColor: color.colorhex }}>
                                              <b>{color.selectedColor}</b>, 
                                            </span>
                                          
                                          ))}
                                            </p>
                                        </div>
                                        <div className="col-2">  
                                          <FormRow name={"printingMount"} 
                                            handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                            labelText={"Printing Mount"} 
                                            value={x.printingMount} type={"text"} 
                                            mandatory={true}  errorobj={""} />                                
                                        </div>
                                        <div className="col-2">   
                                            <FormRow name={"printingkg"} 
                                            handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                            labelText={"Printing (KG)"} 
                                            value={x.printingkg} type={"text"} 
                                            mandatory={true}  errorobj={""} />                           
                                        </div> 
                                        <div className ="col-10">
                                            <FormRow name={"printingQARemark"} 
                                          handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                          labelText={"Printing QA Remark"} 
                                          value={x.printingQARemark} type={"textarea"} 
                                          mandatory={true}  errorobj={""} />      
                                        </div>
                                    </div>
                                  </section>
                                : ""}
                                {(orderDetails.orderStatus == "ORDER PUNCH") ? 
                                <section>
                              <h2>Job - {i + 1}
                                {/* <div className="col-2 addbuttonclass">
                                  <input type="button" id="btnAdd" value="Add more jobs" className="btn btn-primary ml15"  onClick={(evnt) => handleAddJobs(i, evnt)} />
                                </div> */}
                                <span class="form-group header_add">
                                  {orderDetails.jobList.length - 1 == i ? (
                                    <button id="add" type="button" className="gpbtn" onClick={(evnt) => handleAddJobs(i, evnt)}>
                                      Add Jobs
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {orderDetails.jobList.length - 1 != 0 ? (
                                    <button id="add" type="button" className="gpbtn" onClick={(evnt) => handleRemoveJobs(i, evnt)}>
                                      Remove Jobs
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </h2>  
                              
                              <div className="row ">
                              <div className="col-2">
                              <label>Select Job</label>
                                    <select className="form-control" name="joblist" 
                                    onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                    value={x.joblist} required> 
                                        <option id="0" value="0">New Job</option>
                                        <option id="1" value="1">Job 1</option>
                                        <option id="2" value="2">Job 2</option>
                                        <option id="3" value="3">Job 3</option>
                                        <option id="4" value="4">Job 4</option>
                                        <option id="5" value="5">Job 5</option>
                                        <option id="6" value="6">Job 6</option>
                                    </select>
                                </div>
                                {(x.joblist == "0") ? <div className="col-3"> 
                                <FormRow name={"predjobname"} handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} labelText={"Enter Job name"} value={x.predjobname} type={"text"} mandatory={true}  errorobj={""} />                               
                                                  </div> : "" }
                                  <div className="col-2">
                                    <label>Type of Products</label>
                                    <select className="form-control" name="productType" 
                                    onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                    value={x.productType} required> 
                                        <option id="0" value="">Select product type</option>
                                        <option id="1" value="bag">Bag</option>
                                        <option id="2" value="sheet">Sheet</option>
                                        <option id="3" value="tubing">Tubing (LFT)</option>
                                        <option id="4" value="laminatedpouch">Laminated Pouch </option>
                                        <option id="5" value="laminatedroll">Laminated Roll</option>
                                        <option id="6" value="paperbags">Paper Bags </option>
                                        <option id="7" value="exportbags">Export Bags </option>
                                        <option id="8" value="wastage">Wastage </option>
                                        <option id="9" value="others">Others</option>
                                    </select>
                                </div>
                                {/* <div className="col-2"> */}
                                    {/* <label htmlFor="priority-dropdown">Priority:</label> */}
                                    {/* <select
                                    id="priority-dropdown"
                                    value={x.priority}
                                    name="priority"
                                    onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                    >
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                    </select>           */}
                                {/* </div> */}
                                {(x.productType == "bag") ? 
                                <>
                                    <div className="col-4">
                                      <label htmlFor="subbagType">Bag Type</label>
                                      <select value={x.subbagType} name="subbagType" required onChange={(evnt) => handleJobChangeCommaon(i, evnt)}>
                                          <option value="carry-bags">Carry Bags</option>
                                          <option value="courier-bags">Courier Bag</option>
                                          <option value="courier-bags-pod">Courier Bags with pod</option>
                                          <option value="flap-tap-bags">Flap Tap Bags</option>
                                          <option value="others">others</option> 
                                      </select>
                                    </div> 
                                    {(x.subbagType == "carry-bags") ? 
                                      <>
                                      <div className="col-2">
                                      <label htmlFor="cutType">Cut Type</label>
                                      <select value={x.cutType} name="cutType" required onChange={(evnt) => handleJobChangeCommaon(i, evnt)}>
                                          <option value="D-cut">D-cut</option>
                                          <option value="Euro Cut">Euro Cut</option>
                                          <option value="Loop handle">Loop handle</option>
                                          <option value="Patch handle">Patch handle</option>
                                          <option value="Polydraw bags">Polydraw bags</option>
                                      </select>
                                    </div>
                                    {(x.cutType == "D-cut") ? 
                                    <div className="col-2">
                                    <FormRow name={"dcuttlessweight"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"Less Weight"} value={x.dcuttlessweight} type={"text"} mandatory={true}  errorobj={""} />
                                    </div>:""}
                                    {(x.subbagType == "carry-bags") ? <div className="col-2">
                                      <FormRow name={"extraweight"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"Extra Weight"} value={x.extraweight} type={"text"} mandatory={true}  errorobj={""} />
                                    </div> : ""}
                                    </> :""
                                    }
                                </>
                           
                                : ""}
                              {
                                (x.productType == "sheet") ?
                                <> 
                                <div className="col-2">
                                <label htmlFor="subproducttype">Sub Product Type:</label>
                                    <select value={x.subproducttype} onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="subproducttype" name="subproducttype">
                                      
                                        <option value="cutting">Cutting</option> 
                                        <option value="roll">Roll </option>
                                    </select> 
                                </div>
                                {/* {(x.subproducttype == "cutting") ?
                                <div className="col-2">
                                  <label htmlFor="typeOfCut">Cut Type:</label>
                                      <select value={x.TypeOfCut} onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="typeOfCut" name="TypeOfCut">
                                          <option value="OSC">One Side Cut</option>
                                          <option value="BSC">Both Side Cut</option>
                                      </select> 
                                  </div>
                                  : ""} */}
                                <div className="col-2">
                                <label htmlFor="treatment">Treatment:</label>
                                    <select value={x.Treatment} onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="treatment" name="Treatment">
                                        <option value="onseside">One Side </option>
                                        {/* <option value="bothside">Both Side</option> */}
                                        <option value="no">No</option>
                                    </select> 
                                </div></>
                                : ""
                              }
                              {
                                (x.productType == "laminatedpouch" || x.productType == "laminatedroll") ?
                                <> 
                                {(x.productType == "laminatedpouch") ? 
                                <div className="col-2">
                                <label htmlFor="subproducttype">Sub Product Type:</label>
                                    <select value={x.subproducttype} onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="subproducttype" name="subproducttype">
                                        
                                        <option value="sswoz">Side Seal w/o Zipper</option>
                                        <option value="sswz">Side Seal w/ Zipper</option>
                                        <option value="bsp">Bottom Seal Pouch</option>
                                        <option value="bspc">Bottom Seal Pouch w/ Center Seal</option>

                                    </select> 
                                </div> : ""}
                            

                               </>
                                : ""
                              }
                               {
                                (x.productType == "tubing") ?
                                <>  
                                <div className="col-2">
                                  <label htmlFor="typeOfCut">Cut Type:</label>
                                      <select value={x.TypeOfCut} onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="typeOfCut" name="TypeOfCut">
                                          <option value="OSC">One Side Cut</option>
                                          <option value="BSC">Both Side Cut</option>
                                          <option value="NA">NA</option>
                                      </select> 
                                  </div>
                                  
                                <div className="col-2">
                                <label htmlFor="treatment">Treatment:</label>
                                    <select value={x.Treatment} onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="treatment" name="Treatment">
                                        <option value="onseside">One Side </option>
                                        <option value="bothside">Both Side</option>
                                        <option value="na">NA</option>
                                    </select> 
                                </div></>
                                : ""
                              }
                          </div>
                          <div className="row radio_container">
                        
                                  {
                                      (x.productType == "bag" || x.productType == "sheet" || x.productType == "tubing" ) ? 
                                          <>
                                              <div className="col-2">
                                                  <input
                                                  type="radio"
                                                  id={"plain" + i}
                                                  name={"bagType" + i}
                                                  value="plain"
                                                  checked={x.bagType === "plain"}
                                                  onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                                  
                                                  />
                                                  <label htmlFor={"plain" + i} className="label-radio">Plain</label>
                                              </div>
                                              <div className="col-2">
                                                  <input
                                                  type="radio"
                                                  id={"printing" + i}
                                                  name={"bagType" + i}
                                                  value="printing"
                                                  checked={x.bagType === "printing"}
                                                  onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                                
                                                  />
                                                  <label htmlFor={"printing" + i}  className="label-radio">Printing</label>
                                              </div>
                                              {
                                                  (x.bagType == "printing") ? 
                                                  <>
                                                  <div className="col-2">
                                                  <input
                                                  type="radio"
                                                  id={"recylcle" + i}
                                                  name={"printedType" + i}
                                                  value="recycle"
                                                  checked={x.printedType === "recycle"}
                                                  onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                                
                                                  />
                                                  <label htmlFor={"recylcle" + i}  className="label-radio">Only Recycle</label>
                                              </div>
                                              <div className="col-3">
                                                  <input
                                                  type="radio"
                                                  id={"recylcle_job" + i}
                                                  name={"printedType" + i}
                                                  value="recycle_job"
                                                  checked={x.printedType === "recycle_job"}
                                                  onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                              
                                                  />
                                                  <label htmlFor={"recylcle_job" + i} className="label-radio">Recycle with Job</label>
                                              </div> </> : ""
                                              }
                                            
                                          </>
                                      :
                                          ""
                                  }
                          </div>
                             {
                              (x.bagType == "printing") ?
                                  <div className="row radio_container">
                                    {(x.printedType == "recycle_job") ?  
                                                  <div className="col-3">
                        <FormRow name={"jobname"} handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} labelText={"Enter Recycle Job name"} value={x.jobname} type={"jobname"} mandatory={true}  errorobj={""} />                               
                                                  </div>  
                                              : ""}
                                    
                                    {x.printingcolors.map((color, colorindex) => (
                                      <div className="row col-6">
                                      <div className="col-4">
                                        <div className="form-group">
                                      <label class="text-capitalize" for="">Select color</label>
        <select
          key={colorindex}
          value={color.selectedColor}
          name="selectedColor"
          onChange={(event) => handleColorChange(event, colorindex, i)}
        >
          <option value="">-- Select Color --</option>
          <option value="Cyan">Cyan</option>
          <option value="Magenta">Magenta</option>
          <option value="Yellow">Yellow</option>
          <option value="Black">Black</option>
          <option value="Red">Red</option>
          <option value="Purple">Purple</option>
          <option value="Orange">Orange</option>
          <option value="Brown">Brown</option>
          <option value="Blue">Blue</option>
          <option value="gold">Gold</option>
          <option value="other">Special Color</option>
        </select>
        </div>
        </div>
        <div class="col-4">
          <FormRow
														name={"colorhex"}
														id={i}
														handleChange={(event) => handleColorChange(event,colorindex, i)}
														labelText={"Enter Color Hex code"}
														type={"text"}
														//errorobj={errors["optiontext" + i]}
														value={color.colorhex}
														maxlength={25}
													/>
        </div>
        <div className="col-4 header_add">
        {x.printingcolors.length - 1 == colorindex ? (
													 
                            <button type="button" className="gpbtn mt28"  onClick={(evnt) => handleAddColor(colorindex, evnt,i)}>+</button>
													) : (
														""
													)}

													{x.printingcolors.length - 1 != 0 ? (
														  <button type="button" className="gpbtn mt28" onClick={(evnt) => handleRemoveColor(colorindex, evnt,i)}>-</button>
													) : (
														""
													)}
        
      
      </div>    
        </div>
      ))}
      {/* {x.printingcolors.map((color, colorindex) => (
  <div className="row col-6" key={colorindex}>
  <div className="col-4">
    <div className="form-group">
      <label className="text-capitalize" htmlFor="">
        Select color(s)
      </label>
      {['red', 'green', 'blue', 'yellow', 'orange', 'purple', 'pink', 'brown', 'gold', 'other'].map((colorValue) => (
         <div key={colorValue}>
          <input
            type="checkbox"
            id={colorValue}
            name="selectedColor"
            value={colorValue} 
            checked={color.selectedColors.includes(colorValue)}
            onChange={(event) => handleColorChange(event, colorindex, i)}
          />
          <label htmlFor={colorValue}>{colorValue}</label>
          {color.selectedColor === colorValue && (
            <input
              type="text"
              name="colorhex"
              value={color.colorhex}
              onChange={(event) => handleColorChange(event, colorindex, i)}
              placeholder="Enter Color Hex code"
            />
          )}
        </div>
      ))}
    </div>
  </div>

</div>
))} */}
                            
                                    </div> 
                               
                              : 
                              ""
                             }
                              
                              
                          <div className="row sub-sub-containers">
                              <h4>Basic Order details</h4>

                            <div className="col-2">
                            <label htmlFor="handleType">Material:</label>
                            {(x.productType == "laminatedpouch" || x.productType == "laminatedroll") ?
                            <select  value={x.selectedMaterialLamination} 
                            onChange={(evnt) => handleJobChangeLaminationCommaon(i, evnt)} name="selectedMaterialLamination">
                               <option value="">Select Material Type </option>
                              <option value="BOPP + BOPP">BOPP + BOPP </option>
<option value="BOPP+ BOPP Met">BOPP+ BOPP Met </option>
<option value="BOPP+ CPP Met">BOPP+  CPP Met </option>
<option value="BOPP+ LDPE Met">BOPP+ LDPE nat </option>
<option value="BOPP+ CPP NAT">BOPP+ CPP Nat </option>
<option value="BOPP+ BOPP thermal">BOPP+ BOPP thermal </option>

<option value="LDPE+ LDPE">LDPE+ LDPE</option>
<option value="Ldpe + Ldpe milky">Ldpe + Ldpe milky</option>
<option value="LDPE +  NYLON Miky">LDPE +  NYLON Miky</option>
<option value="LDPE +  NYLON Nat.">LDPE +  NYLON Nat.</option>
<option value="Ldpe+CPP nat">Ldpe+CPP nat</option>

<option value="PET+ LDPE Nat">PET+ LDPE Nat</option>
<option value="PET+ LDPE milky">PET+ LDPE milky</option>
<option value="PET+BOPP">PET+BOPP</option>
<option value="PET+ CPP Met">PET+ CPP Met</option>
<option value="PET+NYLON">PET+NYLON</option>
<option value="PET+BOPP PEARL">PET+BOPP PEARL</option>
<option value="PET+ CPP Nat">PET+ CPP Nat</option>

<option value="BOPP Met+AL FOIL+ LDPE Nat">BOPP Met+AL FOIL+ LDPE Nat</option>
<option value="PET+ AL FOIL+ LDPE Nat">PET+ AL FOIL+ LDPE Nat</option>
<option value="PET+BOPP+ LDPE Nat">PET+BOPP+ LDPE Nat</option>
<option value="PET+MET PET+LDPE Nat">PET+MET PET+LDPE Nat</option> 

<option value="Paper+Al foil+ Ldpe Nat">Paper+Al foil+ Ldpe Nat</option>
<option value="Paper+ Ldpe Nat">Paper+ Ldpe Nat</option>

                            </select>
                            :
                            <select
                                id="material-dropdown"
                                value={x.selectedMaterial} 
                                onChange={(evnt) => handleJobChangeCommaon(i, evnt)} name="selectedMaterial"
                              >
                                <option value="LDNatural">LD Natural </option>
                                <option value="LDMilky">LD Milky</option>
                                <option value="LDShrink">LD Shrink</option>
                                <option value="HM">HM</option>
                                <option value="PP">PP</option>
                                <option value="BOPP">BOPP</option>
                                <option value="other">Other</option>
                              </select>
                            }
                            </div>
                            {(x.selectedMaterial =="other") ? 
                            <div className="col-2">
                            <FormRow name={"selectedMaterialtype"} handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} labelText={"Material Type"} value={x.selectedMaterialtype} type={"text"} mandatory={true}  errorobj={""}  />
                            </div>
                            : ""

                            }
                            {(x.productType == "laminatedpouch" || x.productType == "laminatedroll") ? 
                            "": <div className="col-2">
                            <FormRow name={"density"} handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} labelText={"Density"} value={x.density} type={"text"} mandatory={true}  errorobj={""}  />
                            </div>}
                            {/* <div className="col-2">
                              
                            <label htmlFor="material-type-dropdown">Material Type:</label>
                            <select
                              id="material-type-dropdown"
                              value={x.selectedType}
                              onChange={(evnt) => handleJobChangeCommaon(i, evnt)} name="selectedType"
                            >
                              <option value="Natural">Natural</option>
                              {(x.subbagType == "carry-bag") ? "" : <option value="Shrink">Shrink</option>}
                              <option value="Milky">Milky</option>
                              <option value="Others">Others</option>
                            </select>
                            </div>
                            {(x.selectedType == "Others") ? 
                              <div className="col-2"> 
                              <FormRow name={"materialtypeother"} handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} labelText={"Other Material"} value={x.materialtypeother} type={"text"} mandatory={true}  errorobj={""}  />
                              </div>
                            : ""} */}
                            
                            <div className="col-2">
                                  <FormRow name={"width"} handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} labelText={"Width"} value={x.width} type={"text"} mandatory={true}  errorobj={""} unit={unit} />
                            </div>
                            {(x.productType == "tubing") ?
                           ""
                            : ((x.productType == "sheet" && x.subproducttype == "roll") || (x.productType == "laminatedroll")) ?  "" : <div className="col-2"> 
                            <FormRow name={"length"}handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} labelText={"Length"} value={x.length} type={"text"} mandatory={true}  errorobj={""} unit={unit}/>
                      </div> }
                            <div className="col-2">
                            <FormRow name={"micron"}handleChange={(evnt) => calculateMicronGauge(i, evnt)} labelText={"Micron"} value={x.micron} type={"text"} mandatory={true}  errorobj={""} />
                            </div>
                            <div className="col-2">
                            <FormRow name={"gauge"}handleChange={(evnt) => calculateMicronGauge(i, evnt)} labelText={"Gauge"} value={x.gauge} type={"text"} mandatory={true}  errorobj={""} />
                            </div>
                            {(x.productType == "laminatedpouch" || x.productType == "laminatedroll") ?
                              <div className="row"> 
                                {x.laminationDetails.map((item,index)=>(
                              <>
                              <div className="col-md-1">
                              <label htmlFor={`MaterialType-${index}`}>Material Type:</label>
                                <p>{item.materialType}</p>
                              </div>
                              {/* <div className="col-1">
                              <label htmlFor={`qty-type-dropdown-${index}`}>Qty Type:</label>
                                <select
                                  id={`qty-type-dropdown-${index}`}
                                  value={selectedLaminationMaterial[index].qtyType}
                                  name="qtyType"
                                  onChange={(event) => handleJobChangeLaminateCommon(index, event)}
                                >
                                  <option value="Kgs">Kgs</option>
                                  <option value="Meter">Meter</option>
                                </select>
                                            
                             </div> */}
                             <div className="col-2">
                  <label htmlFor={`qtylaminatekgs-${index}`}>Quantity - Kgs:</label>
                  <input
                    type="number"
                    id={`qtylaminatekgs-${index}`}
                    name="qtylaminatekgs"
                    value={item.qtylaminatekgs}
                    class="form-control"
                    
                    onChange={(event) => handleJobChangeLaminateCommon(event,index,i)}
                    
                  />
                </div>
                             <div className="col-2">
                  <label htmlFor={`qtylaminatemeter-${index}`}>Quantity - Meter:</label>
                  <input
                    type="number"
                    id={`qtylaminatemeter-${index}`}
                    name="qtylaminatemeter"
                    value={item.qtylaminatemeter}
                    class="form-control"
                    onChange={(event) => handleJobChangeLaminateCommon(event,index,i)}
                  />
                </div></>
                            ))} 
                            { (x.productType == "laminatedroll") ? 
                            <FormRow name={"laminiateorder"}handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} labelText={"Order in KGs"} value={x.laminiateorder} type={"text"} mandatory={true}  errorobj={""} />
                            : ""}
                              </div> : ""}
                          {(x.productType == "bag" || (x.subproducttype == "cutting" || typeof(x.subproducttype) == "undefined") && (x.productType == "sheet")) ? 
                            <><div className="col-1">
                                  <label htmlFor="qty-type-dropdown">Qty Type:</label>
                                  <select
                                  id="qty-type-dropdown"
                                  value={x.qtyType}
                                  name="qtyType"
                                  onChange={(evnt) => handleJobChangeCommaon(i, evnt)}
                                  >
                                  <option value="Kgs">Kgs</option>
                                  <option value="Pcs">Pcs</option> 
                                  </select>
                                                
                            </div>
                            <div className="col-2">
                              <FormRow name={"qtyValue"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"Quantity - " + x.qtyType } value={x.qtyValue} type={"text"} mandatory={true}  errorobj={""} />
                            </div></> :
                            ((x.productType == "sheet" && x.subproducttype == "roll") || (x.productType == "tubing")) ? 
                            <>
                            <div className="col-2">
                              <FormRow name={"rollQty"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"Roll Quantity"} value={x.rollQty} type={"text"} mandatory={true}  errorobj={""} />
                            </div>

                            <div className="col-2">
                              <FormRow name={"qtySheetValueKG"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"Quantity - KGS" } value={x.qtySheetValueKG} type={"text"} mandatory={true}  errorobj={""} />
                            </div>
                            <div className="col-2">
                              <FormRow name={"qtySheetValueMeter"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"Quantity - Meter"} value={x.qtySheetValueMeter} type={"text"} mandatory={true}  errorobj={""} />
                            </div></> : ""
                            }
                            
                            {(x.subbagType == "courier-bags-pod") ? 
                            <>
                            {/* <div className="col-2">
                              <FormRow name={"podwidth"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"POD Width - " } value={x.podwidth} type={"text"} mandatory={true}  errorobj={""} />
                            </div> */}
                            
                            <div className="col-2">
                              <FormRow name={"podgauge"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"POD Gauge - " } value={x.podgauge} type={"text"} mandatory={true}  errorobj={""} />
                            </div>
                            
                            <div className="col-2">
                              <FormRow name={"podsize"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"POD Size - "} value={x.podsize} type={"text"} mandatory={true}  errorobj={""} />
                            </div>
                           <div className="col-2">
                           <label>POD Weight</label>
                            <p>{x.podcalcwt}</p>
                           </div>
                            </>
                            : ""}
                             {(x.subproducttype == "roll" || x.productType == "tubing") ? 
                            "" : 
                            <>
                           
                            {(x.productType == "laminatedpouch" || x.productType == "laminatedroll") ? ""
                            :<>
                             <div className="col-2">
                                  <label>{(x.qtyType == "Kgs") ? "Quantity in Pcs" :""}</label>
                                  <p>{ (x.qtyType == "Kgs") ?  x.qtyValuepc : ""}</p> 
                            </div> <div className="col-3">
                                  <label>Total Material Required - Kgs</label>
                                  <p>{x.totalkgrequired}</p>
                            </div></>
                            }
                            {(x.productType == "bag" || x.productType == "sheet") ?
                            <div className="col-2">
                            <label>Weight per {(x.productType == 'bag') ?  "bag" : "sheet"}</label>
                                 
                                  <p>{x.weightPerBag}</p>
                            </div> : ""}
                            {(x.productType == "laminatedpouch") ?
                              <div className="col-2">
                               <FormRow name={"weighperpouch"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"Weight Per Pouch"} value={x.weighperpouch} type={"text"} mandatory={true}  errorobj={""} />
                              </div> : ""
                            }
                              {(x.productType == "laminatedroll") ?
                              <div className="col-2">
                               <FormRow name={"weighperrolllamination"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"Weight Per Roll"} value={x.weighperrolllamination} type={"text"} mandatory={true}  errorobj={""} />
                              </div> : ""
                            }
                            </>}
                            
                            <div className="col-2">
                            <FormRow name={"exqtyallowed"} handleChange={(evnt) => handleJobChangeCommaon(i,evnt)} labelText={"Excess Qty Allowed(%)"} value={x.exqtyallowed} type={"text"} mandatory={true}  errorobj={""} />
                            </div> 
                            
                            {(x.subbagType == "courier-bags-pod") ? 
                            (x.subproducttype != "roll") ? 
                            <div className="col-2">
                                  <label>Total Weight per  {(x.productType == 'bag') ?  "bag" : "sheet"}</label>
                                  <p>{parseFloat(x.weightPerBag) + parseFloat(x.podcalcwt)}</p>
                            </div>: "" : ""}
                          </div>
                          {(x.productType == "bag") ? 
                          <div className="row sub-sub-containers">
                            <h4>Bag Job Specification</h4>
                            {(x.subbagType != "carry-bags") ? 
                              <><div className="col-2">
                                  <label htmlFor="flapType">Flap Type:</label>
                                  <select value={x.flapType} name="flapType" onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="flapType">
                                      <option value="OFT">Open Flap Tape (OFT)</option>
                                      <option value="OF">Open Flap (OF)</option>
                                      <option value="NA">NA</option>
                                  </select> 
                              </div>
                              <div className="col-2"> 
                                <label htmlFor="flapType">Flap Size</label>
                                <div>
                                <label>
                                  <input
                                    type="radio"
                                    value="yes"
                                    name="flapSizeYN"
                                    checked={x.flapSizeYN === 'yes'} 
                                    onChange={(evnt) => handleflapSizeYNChange(i, evnt)}
                                  />
                                  Yes
                                </label>

                                <label className="ml15">
                                  <input
                                  name="flapSizeYN"
                                    type="radio"
                                    value="no"
                                    checked={x.flapSizeYN === 'no'} 
                                    onChange={(evnt) => handleflapSizeYNChange(i, evnt)}
                                  />
                                  No
                                </label>
                                </div>
                              </div>
                              {(x.flapSizeYN == "yes") ?
                              <div className="col-2">
                                <FormRow name={"flapSize"}handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} labelText={"Flap Size"} value={x.flapSize} type={"text"} mandatory={true}  errorobj={""} />
                            </div> : ''}
                            </>
                              : ""}
                            <div className="col-2">
                                  <label htmlFor="gusset">Gudget:</label>
                                  {(x.subbagType == "courier-bags" || x.subbagType == "courier-bags-pod" || x.subbagType == "flap-tap-bags") ?
                                     <select defaultValue="Bottom" value={x.gusset} name={"gusset"} onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="gusset">
                                          <option value="-1">Select Gudget</option>
                                          <option value="Bottom">Bottom</option>
                                  </select> 
                                  :
                                  (x.subbagType == "carry-bags") ?
                                  <select value={x.gusset} name={"gusset"} onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="gusset">
                                     <option value="Side">Side</option>
                                  <option value="Bottom">Bottom</option>
                                    <option value="NA">NA</option>
                                  </select>
                                  : 
                                  <select value={x.gusset} name={"gusset"} onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="gusset">
                                   <option value="NA">NA</option>
                                  <option value="Side">Side</option>
                                  <option value="Bottom">Bottom</option>
                              </select> 
                                  }
                               
                              </div>
                              {(x.gusset != "NA") ? 
                              <div className="col-2"> 
                                <label htmlFor="gudgetSize">Gudget Size</label>
                                <div>
                                <label>
                                  <input
                                    type="radio"
                                    value="yes"
                                    name="gudetSiseYN"
                                    checked={x.gudetSiseYN === 'yes'}
                                    //onChange={handelegudetSiseYN}
                                    onChange={(evnt) => handleflapSizeYNChange(i, evnt)}
                                  />
                                  Yes
                                </label>

                                <label className="ml15">
                                  <input
                                    type="radio"
                                    value="no"
                                    checked={x.gudetSiseYN === 'no'}
                                    name="gudetSiseYN"
                                    onChange={(evnt) => handleflapSizeYNChange(i, evnt)}
                                  />
                                  No
                                </label>
                                </div>
                              </div> : ""}
                              {(x.gudetSiseYN == 'yes' && x.gusset!="NA") ? 
                              <div className="col-2">
                                  <FormRow name={"gussetSize"}handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} labelText={"Gudget Size"} value={x.gussetSize} type={"text"} mandatory={true}  errorobj={""} />
                              </div> : ""}
                              {(x.gusset != "NA") ?
                             (x.gussetSize > 0 && x.gusset != "Bottom") ?  <div className="col-2">
                                <label>Gudget Size</label>
                                <p>{x.gussetSize} {(x.gusset == "Bottom") ? "/" : "+"} {x.gussetSize}</p>
                              </div> : "" : ""}
                            <div className="col-2">
                            <label htmlFor="sealing">Sealing</label>
                                  <select value={x.sealing} name={"sealing"} onChange={(evnt) => handleJobChangeCommaon(i, evnt)} id="sealing">
                                  <option value="-1">Select Sealing</option>
                                      {/* <option value="Side">Side</option> */}
                                      { 
  x.subbagType === "carry-bags" ? (
    <>
      {x.gusset === "Side" && x.gudetSiseYN === "yes" ? (
        <option value="Bottom">Bottom</option>
      ) : 
      (x.gusset === "Bottom") && (x.gudetSiseYN === "yes") ? (
        <option value="Side">Side</option>
      ) : <><option value="NA">NA</option><option value="Side">Side</option><option value="Bottom">Bottom</option></>}
    </>
  ) : null 
}
{ 
  x.subbagType == "flap-tap-bags" || x.subbagType === "courier-bags" || x.subbagType == "courier-bags-pod" ? (
    <>
      <option value="Side">Side</option>
    </>
  ) : null 
}
{ x.subbagType === "others" ? (
    x.gudetSiseYN === "no" && x.flapSizeYN === "no"  ? (
        <>
            <option value="Side">Side</option>
            <option value="Bottom">Bottom</option>
        </>
    ) : (x.flapSizeYN === "yes") ? (
        <option value="Side">Side</option>
    ) :  <option value="Side">Side</option>
) : null }

                                  </select>
                            </div>
                           
                          <div className="col-2"> 
                                <label htmlFor="gudgetSize">Hole Punch</label>
                                <div>
                                <label>
                                  <input
                                    type="radio"
                                    value="yes"
                                    checked={x.holepunchSizeYN == 'yes'}
                                    // onChange={handleholepunchSizeYN}
                                    name="holepunchSizeYN"
                                    onChange={(evnt) => handleflapSizeYNChange(i, evnt)}
                                  />
                                  Yes
                                </label>

                                <label className="ml15">
                                  <input
                                    type="radio"
                                    value="no"
                                    checked={x.holepunchSizeYN == 'no'}
                                    name="holepunchSizeYN"
                                    onChange={(evnt) => handleflapSizeYNChange(i, evnt)}
                                  />
                                  No
                                </label>
                                </div>
                              
                              </div>
                              {(x.holepunchSizeYN == "yes") ?
                            <><div className="col-2">
                                  <FormRow name={"holePunchSize"}handleChange={(evnt) => handleJobChangeCommaon(i, evnt)}  labelText={"Hole Punch Size"} value={x.holePunchSize} type={"text"} mandatory={true}  errorobj={""} />
                            </div>
                            <div className="col-2">
                                  <FormRow name={"holePunchNumber"}handleChange={(evnt) => handleJobChangeCommaon(i, evnt)}  labelText={"Hole Punch Number"} value={x.holePunchNumber} type={"text"} mandatory={true}  errorobj={""} />
                            </div></>
                            : ""}
                          </div>
                          : "" }
                            
                                 </section> : ""}
                                 <div>
                           <FormRow name={"jobremark"} 
                                            handleChange={(evnt) => handleJobChangeCommaon(i, evnt)} 
                                            labelText={"Job Remark"} 
                                            value={x.jobremark} type={"textarea"} 
                                            mandatory={true}  errorobj={""} />     
                           </div>
                               </section> 
                              
                                 
                                 
                                
                              </>
                              ))}
                          




                          
                          
                        {/* <div className="scheduleDTS">
                          <div className="dflexstart commonInputBtnWpr">
                            <div className="form-group">
                              <label>Select campaign schedule Date time</label>
                            
                              <DatePicker
                                minDate={new Date()}
                                timeInputLabel="Time:"
                                showTimeInput
                                selected=""
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className="form-control"
                                name="scheduledDTS"
                                onChange={(date) => setStartDate(date)}
                              />
                            </div>
                          </div>
                        </div>  */}
                    </section> 
                    }
                            </div>
                            <div className="form-group campaignsubmitbtn">
								<input type="submit" id="btnSubmit" value="Submit" className="btn btn-primary" />
                <input type="button" id="btnSave" value="Save" className="btn btn-primary"  onClick={handleSave}/>
								<input type="button" id="btnCancel" value="Cancel" className="btn btn-primary ml15" onClick={handleCancle} />
							</div>
					</div>
                    </form>
				</div>
        <div>{tempSubmitData.map((x) => x)}</div>
                </div>
			</GBMCampaignWrapper>
		</>
	);
};
export default CreateOrder;
