import { Outlet } from 'react-router-dom';
import { BigSidebar, Navbar, SmallSidebar } from '../../components';
import Wrapper from '../../assets/wrappers/SharedLayout';
const SharedLayout = () => {
  return (
    <Wrapper>
      <main className='dashboard'>
        <SmallSidebar />
        <BigSidebar />
        <div>
          <Navbar />
          <div className='container'>
            <Outlet />
          </div>
            {/* <div className='footer'><span><b><u>Disclaimer:</u></b></span>  The information contained on this website is confidential and intended only for the use of the individual or entity to which it is addressed. Unauthorized access, use, dissemination, forwarding, printing or copying of this information is strictly prohibited and may be unlawful.</div> */}
        </div>
      </main>
    </Wrapper>
  );
};
export default SharedLayout;
