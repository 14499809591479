import React from "react";

const SearchBar = ({ searchQuery, setSearchQuery }) => {
	const handleSearchProps = (event) => {
		setSearchQuery(event.target.value);
	};

	return (
		<div className="reportSearchBox">
			<input type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchProps} className="p10" />
		</div>
	);
};

export default SearchBar;
