import styled from 'styled-components'

const Wrapper = styled.section`
  .dashboard {
    display: grid;
    grid-template-columns: 1fr;
  }
  .dashboard-page {
    width: 100%;
    margin: 0 auto;
    padding: 2rem 0;
  }
  @media (min-width: 992px) {
    .dashboard {
      grid-template-columns: auto 1fr;
    }
    .dashboard-page {
      width: 100%;
    }
  }
  .footer{
    position:fixed;
    bottom:0;
        padding-left: 20px;
        padding-top:10px;
        background:#fff;
        border-top:1px solid #e6e6e6;
        color:red;
  }
`
export default Wrapper
