import React, { useState, useEffect } from "react";
import { event } from "react-ga";
import { NavLink } from "react-router-dom";

const Accordion = ({ title, content, click, parentIsActive, childlength }) => {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (typeof parentIsActive != "undefined" && parentIsActive == true) {
      setIsActive(parentIsActive);
    } else {
      setIsActive(false);
    }
  }, []);
  return (
    // (childlength > 0)?
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>
          {title} <span>{isActive ? "-" : "+"}</span>
        </div>
      </div>

      {isActive == true ? (
        <div className="accordion-content">
          {content.map(function (x) {
            if (x.category_name == title) {
              return (
                <NavLink
                  to={x.module_url}
                  state={{
                    weburl: x.module_url,
                    title: x.module_name,
                    description: x.description,
                  }}
                  className={({ isActive }) => {
                    return isActive ? "nav-link active" : "nav-link";
                  }}
                  key={x.id}
                  onClick={click}
                  id="Module"
                >
                  {x.module_name}
                </NavLink>
              );
            }
          })}
        </div>
      ) : (
        ""
      )}
    </div>
    // : ""
  );
};

export default Accordion;
