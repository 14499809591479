import axios from 'axios';
import { clearStore } from '../features/user/userSlice';
import { getUserFromLocalStorage } from './localStorage';
var x_api_key = "fR2HbWKooT6Uf4xo5R7iD2PetkcXvWzT1g1xDV29";

const customFetch = axios.create({
  baseURL: 'https://etsvoice.knowlarity.com/role_management/api/',
  //baseURL: 'https://dev-unify.gupshup.io/russel/role_management_api/',
});
const fileuploadFetch = axios.create({
  baseURL: 'https://etsvoice.knowlarity.com/role_management/api/',
  //baseURL: 'https://dev-unify.gupshup.io/russel/role_management_api/',
});
fileuploadFetch.interceptors.request.use((config) => {
  
    config.headers.common['x-api-key'] = `${x_api_key}`;
    config.headers.common['content-type'] = `multipart/form-data`;
  
//  const user = getUserFromLocalStorage();
//   if (user) {
//   config.headers.common['Authorization'] = `Bearer ${user.token}`;
//   }
  return config;
});
customFetch.interceptors.request.use((config) => { 
    config.headers.common['x-api-key'] = `${x_api_key}`;
    config.headers.common['content-type'] = `application/json`;
//  const user = getUserFromLocalStorage();
//   if (user) {
//   config.headers.common['Authorization'] = `Bearer ${user.token}`;
//   }
  return config;
});

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
  
  if (error.response.status === 401) {
    thunkAPI.dispatch(clearStore());
    return thunkAPI.rejectWithValue('Unauthorized! Logging Out...');
  }
  return thunkAPI.rejectWithValue(error.response.data.msg);
};

export default customFetch;
