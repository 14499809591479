import styled from 'styled-components'

const Wrapper = styled.section`
  .form {
    width: 100%;
    max-width: 100%;
  }
  .form-input,
  .form-select,
  .btn-block {
    height: 35px;
  }
  .form-row {
    margin-bottom: 0;
  }
  .form-center {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    row-gap: 0.5rem;
  }
  h5 {
    font-weight: 700;
  }
  .btn-block {
    align-self: end;
    margin-top: 1rem;
  }
  @media (min-width: 768px) {
    .form-center {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 992px) {
    .form-center {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .btn-block {
      margin-top: 0;
    }
  }
  .searchbox{
    display: inline-block;
    width: 20%;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
  .mrlft-10{
    margin-left:10px;
  }
  .mrtght-10{
    margin-right:10px
  }
  .pretext{
    background:#ececec;
   padding: 8px;
    border: 1px solid #b5b5b5;
    border-right: 0px;
  }
`

export default Wrapper
