import styled from 'styled-components';

const GBMCampaignWrapper = styled.section`
.preview-container{
    border-left:1px solid #ececec;
}.boldcount{
  margin-right: 10px;
  border-right:1px solid #000;
  padding-right:5px;
}`;
  export default GBMCampaignWrapper;
