import Wrapper from "../assets/wrappers/Navbar";
import { FaAlignLeft, FaUserCircle, FaCaretDown } from "react-icons/fa";
import Logo from "./Logo";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar, clearStore } from "../features/user/userSlice";

const Navbar = () => {
  const [showLogout, setShowLogout] = useState(false);
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const toggle = (event) => {
    event.stopPropagation();
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <Wrapper>
      <div className="nav-center">
        <button type="button" className="toggle-btn" onClick={toggle}>
          <FaAlignLeft />
        </button>
        <div>{/* <h3 className='logo-text'>dashboard</h3> */}</div>
        <div className="btn-container">
          <button
            type="button"
            className="btn"
            onClick={(event) => {
              event.stopPropagation();
              setShowLogout(!showLogout);
            }}
          >
            <FaUserCircle />
            {user ? user.user.username : ""}
            <FaCaretDown />
          </button>
          <div
            ref={dropdownRef}
            className={showLogout ? "dropdown show-dropdown" : "dropdown"}
          >
            <button
              type="button"
              className="dropdown-btn"
              onClick={() => dispatch(clearStore("Logging out..."))}
            >
              logout
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Navbar;
