import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const getAllPhoneBookThunk = async (_, thunkAPI) => {
  const { page, search, searchStatus, searchType, sort,limit} =
    thunkAPI.getState().phoneBooklist;

  // let url = `/jobs?status=${searchStatus}&jobType=${searchType}&sort=${sort}&page=${page}`;
  // if (search) {
  //   url = url + `&search=${search}`;
  // }
  let pagevalue = page;
  if(pagevalue > 1){
    pagevalue= (pagevalue  - 1)* limit;
  }else{
    pagevalue = 0;
  }
  let url = '/getPhonebooklogs'
  let dataObj = {
    "user_id":thunkAPI.getState().user.user.innerSystemData.userdata[0].id,
    "limit":limit,
    "offset":pagevalue
  }
  
  try {
    const resp = await customFetch.post(url,dataObj);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const showStatsThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get('/jobs/stats');

    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
