import styled from 'styled-components'

const Wrapper = styled.section`
    margin-top: 2rem;
    padding: 20px;
    background: #fff;
    font-size: 12px;
    .table tr th {
    font-size:14px;
    }
.table tr td {
    font-size:14px;
}
.align-right{
    text-align:right
}
.table{
        border: 1px solid #ececec;
}
.table tr:first-child th {
    background:#5956D6;
    color:#fff;
}
.form-control{
    border: 1px solid rgba(34, 36, 38, .15);
    background-color: #fff;
    font-size: 1em;
}
.search-container{
    margin-bottom:10px;
    display:block;
}
.search-container input{
    width:50%;
    display:inline-block;
}
`
export default Wrapper