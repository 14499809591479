import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Landing, Error, Register, ProtectedRoute } from './pages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga'; 
import {
  Profile,
   
  SharedLayout,
  
  WebLoaders,
  CronList,
  CreateOrder,ManageOrder
} from './pages/dashboard'; 


function App() {
  //ReactGA.initialize(trackingId); 
  // "homepage": "https://dev-unify.gupshup.io/jimeet/rolemanagement/web/build/  https://etsvoice.knowlarity.com/role_management/web/",
  //"homepage": "https://dev-unify.gupshup.io/jimeet/GBMCampaigm/",http://qa-unify.gupshup.io/GBMcampaign/
  return (
   <BrowserRouter>
{/* <BrowserRouter>  */}
      <Routes>
        <Route
          path='/'
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        > 
          {/* <Route path='all-jobs' element={<AllJobs />} />  */}
          {/* <Route path='usermapping' element={<UserMapping />} /> */}
          <Route path='profile' element={<Profile />} />
          {/* <Route path='phone-book' element={<AllPhoneBook />}/>
          <Route path='create-phone-book' element={<CreatePhoneBook />} />
          <Route path='all-campaign' element={<AllCampaigns />} />
          <Route path='create-campaign' element={<CreateCampaign />} />
          <Route path='orderDetails' element={<OrderDetails />} />
          <Route path='create-sound' element={<CreateSound />} />
          <Route path='manage-sound' element={<AllSound />} /> */}
          {/* <Route path='web-loaders' element={<WebLoaders />} />
          <Route path='cron-list' element={<CronList />} />
          <Route path="gbmcampaignreport" element={<GBMCampaignReport />} />
          <Route path='gbmcampaigndashboard' element={<GBMDashboard />} />
          <Route path='gbmcreatecampaign' element={<GBMCreateCampaign />} />
          <Route path="gbmpushcampaign" element={<GBMManageCampaign />} /> */}
          <Route path="createOrder" element={<CreateOrder />} />
          <Route path="manageOrder" element={<ManageOrder />} />
        </Route>
        {/* <Route path='landing' element={<Landing />} /> */}
        <Route path='register' element={<Register />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <ToastContainer position='top-center' />
    </BrowserRouter>
  );
}

export default App;