import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props}
  </Tooltip>
);
const FormRow = ({
  type,
  name,
  value,
  handleChange,
  labelText,
  mandatory,
  errorobj,
  Tooltip,
  placeholder,
  datavalidationtype,
  id,
  maxlength,
  readOnly,
  unit
}) => { 
  return (
    <div className="form-group">
      <label className="text-capitalize" htmlFor={name}>
        {labelText || name}
        {(unit == "" || typeof unit == "undefined") ? "" : (<span> ({unit}) </span>)}
        {mandatory === true ? <span className="manadatory">*</span> : ""}
        {typeof Tooltip != "undefined" && Tooltip[0] == true ? (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(Tooltip[1])}
            trigger="hover"
          >
            <span className="btn btn-icon">?</span>
          </OverlayTrigger>
        ) : (
          ""
        )}
      </label>
      {type != "textarea" ? (
     
        <input
          data-id={id}
          id={name}
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          className="form-control"
          placeholder={placeholder}
          data-mandatory={mandatory}
          data-validationtype={datavalidationtype}
          maxlength={maxlength}
          readOnly ={readOnly}
          required={mandatory ? true : false}
        />
      ) : (
        ""
      )}
      {type == "textarea" ? (
        <textarea
          id={name}
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          className="form-control"
          placeholder={placeholder}
          data-mandatory={mandatory}
          maxLength={maxlength}
          readOnly ={readOnly}
        ></textarea>
      ) : (
        ""
      )}
      {errorobj && <p className="error-msg">{errorobj}</p>}
    </div>
  );
};
export default FormRow;
