import backgroundImage from '../images/bg.jpg';
import styled from 'styled-components'
const Wrapper = styled.section`
      justify-content: center; 
    -webkit-box-pack: center;
    background:url(${backgroundImage});  
    display: flex;
  .logo {
    display: block; 
  }
  .form {
    width:auto;
    border-top: 5px solid var(--primary-500);
  }

  h3 {
    text-align: center;
  }
 
  .btn {
    margin-top: 1rem;
  }
  .member-btn {
    background: transparent;
    border: transparent;
    color: var(--primary-500);
    cursor: pointer;
    letter-spacing: var(--letterSpacing);
  }
  .login-left{
    width:400px;
    border:1px solid red
  }
  .login-left img{
    margin:0px;
  }.align-middle{
    align-self:center !important
  }
  .login-block{
    padding:60px
  }
  .text-left {
    text-align: left;
}
h2 {
    margin: 10px 0;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    color: #fff;
    font-size: 30px;
}
p {
    margin: 0 0 1em;
    line-height: 1.4285em;
}
span { 
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold;
}
.login-form {
    margin: 30px 0;
}
input {
    border: 1px solid #ced4da !important;
    border-radius: 4px !important;
    font-family: Arial, sans-serif !important;
    color: #282323 !important;
    outline: 0 !important;
    padding: 16px !important;
    font-size: 14px !important;
    font-weight: 400;
    background-color: #ffffff !important;
}

.upgradewpr {
    background: linear-gradient(180deg, #F3EFFF 0%, #FFEDF1 100%);
    padding: 20px;
    text-align: left;
    width: 100%;
    margin-top: -5px;
    border-radius: 4px;
}
.font18 {
    font-size: 18px;
}
.font14{
  font-size:14px;
}
.gs-button {
    border: 1px solid #5956d6;
    background-color: #5956d6;
    border-radius: 6px;
   
    cursor: pointer;
    color: #ffffff;
    outline: 0;
    text-align: center;
    font-size: 16px;
}
`
export default Wrapper
